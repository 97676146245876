<template>
<div>
  <v-tabs v-model="active_tab">
    <v-tab>Product list page settings</v-tab>
    <v-tab>Product detail page settings</v-tab>
    <v-tab-item style="padding-left:15px;padding-top:15px;">
      <div>
        <div style="margin-bottom:15px;">
        <div class="control">
          <label class="label" style="font-weight: bold;font-size:20px;">Product list block settings</label>
        </div>
        <div style="padding:0 15px;">
          <div style="display:flex;align-items:center;margin-bottom:15px;">
            <label class="label" style="width:200px;margin-bottom:0;">
              Width <b>{{ content.styles.product.width }}%</b>
            </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="100"
                    :min="33"
                    v-model="content.styles.product.width"
                ></v-slider>
              </div>
          </div>
          <div style="display:flex;align-items:center;margin-bottom:15px;">
            <label class="label" style="width:200px;margin-bottom:0;">
              Height <b>{{ content.styles.product.height }}%</b>
            </label>
            <div class="slider" style="width:80%;">
              <v-slider
                  hide-details
                  :max="600"
                  :min="30"
                  v-model="content.styles.product.height"
              ></v-slider>
            </div>
          </div>
        </div>
        </div>
        <div style="margin-bottom:15px;">
          <div class="control">
            <label class="label" style="font-weight: bold;font-size:20px;">Name settings</label>
          </div>
          <div style="padding:0 15px;">
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label class="label" style="width:200px;margin-bottom:0;">
                Font Color
              </label>
              <div class="control">
                <v-menu
                    top
                    nudge-left="16"
                    nudge-bottom="98"
                    :close-on-content-click="false"
                    v-model="show_text_color"
                >
                  <template v-slot:activator="{ on }">
                    <div
                        class="custom__menu--color"
                        :style="`background-color:${content.styles.product.color}`"
                        v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                          v-model="content.styles.product.color"
                          flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="width:200px;margin-bottom:0;">
                Font Size <b>{{ content.styles.product.fontSize }}px</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="40"
                    :min="10"
                    v-model="content.styles.product.fontSize"
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="width:200px;margin-bottom:0;">
                Font Weight <b>{{ parseInt(content.styles.product.fontWeight, 10) / 10 }}%</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="1000"
                    :min="100"
                    v-model="content.styles.product.fontWeight"
                    :step="100"
                ></v-slider>
              </div>
            </div>
            <div style="display: flex;justify-content: space-between;">
              <div style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom:0;">
                  Font Style <b>{{ content.styles.product.fontStyle }}</b>
                </label>
                <div class="control">
                  <v-radio-group hide-details row v-model="content.styles.product.fontStyle">
                    <v-radio
                        v-for="(type,typeIndex) in fontStyleTypes"
                        :key="type.id"
                        :label="type.label"
                        :value="type.value"
                        :style="`margin-left:${typeIndex?'15px':0}`"
                    >
                    </v-radio>
                  </v-radio-group>
                </div>
              </div>
              <div style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom:0;">
                  Font Family
                </label>

                <v-select
                    flat
                    dense
                    outlined
                    hide-details
                    placeholder="Select Font Family"
                    :items="fontFamilyTypes"
                    item-text="label"
                    item-value="value"
                    class="canvas__select"
                    v-model="content.styles.product.fontFamily"
                    style="max-width:250px;"
                >
                  <template v-slot:selection="{ item }">
                    <div :style="`font-family:'${item.value}',sans-serif`">
                      {{ item.label }}
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div :style="`font-family:'${item.value}',sans-serif`">
                      {{ item.label }}
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom:15px;">
          <div class="control" >
            <label class="label" style="font-weight: bold;font-size:20px;">Image Settings</label>
          </div>
          <div style="padding:0 15px;">
            <div
                class="control"
                style="display:flex;align-items:center;margin-bottom:15px;"
            >
              <label class="label" style="margin-bottom: 0;width:200px;">Show Image</label>
              <v-switch color="#6ab64a" v-model="content.styles.product.show_icon" hide-details>
              </v-switch>
            </div>
            <div v-if="content.styles.product.show_icon">
              <div style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom:0;">
                  Height <b>{{ content.styles.product.img_height }}</b> px</label>
                <div class="slider" style="width:80%;">
                  <v-slider
                      hide-details
                      :min="10"
                      :max="600"
                      v-model="content.styles.product.img_height"
                  ></v-slider>
                </div>
              </div>
              <div style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom:0;">
                  Width <b>{{ content.styles.product.img_width }}</b> %</label>
                <div class="slider" style="width:80%;">
                  <v-slider
                      hide-details
                      :min="10"
                      :max="100"
                      v-model="content.styles.product.img_width"
                  ></v-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom:15px;">
          <div class="control" >
            <label class="label" style="font-weight: bold;font-size:20px;">Price settings</label>
          </div>
          <div style="padding:0 15px;">
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label class="label" style="width:200px;margin-bottom:0;">
                Font Color
              </label>
              <div class="control">
                <v-menu
                    top
                    nudge-left="16"
                    nudge-bottom="98"
                    :close-on-content-click="false"
                    v-model="show_text_price_color"
                >
                  <template v-slot:activator="{ on }">
                    <div
                        class="custom__menu--color"
                        :style="`background-color:${content.styles.product.colorPrice};`"
                        v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                          v-model="content.styles.product.colorPrice"
                          flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="width:200px;margin-bottom:0;">
                Font Size <b>{{ content.styles.product.fontSizePrice }}px</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="40"
                    :min="10"
                    v-model="content.styles.product.fontSizePrice"
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="width:200px;margin-bottom:0;">
                Font Weight <b>{{ parseInt(content.styles.product.fontWeightPrice, 10) / 10 }}%</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="1000"
                    :min="100"
                    v-model="content.styles.product.fontWeightPrice"
                    :step="100"
                ></v-slider>
              </div>
            </div>
            <div style="display: flex;justify-content: space-between;">
              <div style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom:0;">
                  Font Style <b>{{ content.styles.product.fontStylePrice }}</b>
                </label>
                <div class="control">
                  <v-radio-group hide-details row v-model="content.styles.product.fontStylePrice">
                    <v-radio
                        v-for="(type,typeIndex) in fontStyleTypes"
                        :key="type.id"
                        :label="type.label"
                        :value="type.value"
                        :style="`margin-left:${typeIndex?'15px':0}`"
                    >
                    </v-radio>
                  </v-radio-group>
                </div>
              </div>
              <div style="display:flex;align-items:center;margin-bottom:15px;">
                <label class="label" style="width:200px;margin-bottom:0;">
                  Font Family
                </label>

                <v-select
                    flat
                    dense
                    outlined
                    hide-details
                    placeholder="Select Font Family"
                    :items="fontFamilyTypes"
                    item-text="label"
                    item-value="value"
                    class="canvas__select"
                    v-model="content.styles.product.fontFamilyPrice"
                    style="max-width:250px;"
                >
                  <template v-slot:selection="{ item }">
                    <div :style="`font-family:'${item.value}',sans-serif`">
                      {{ item.label }}
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div :style="`font-family:'${item.value}',sans-serif`">
                      {{ item.label }}
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom:15px;">
          <div class="control" >
            <label class="label" style="font-weight: bold;font-size:20px;">Button cart settings</label>
          </div>
          <div style="padding:0 15px;">
            <div style="display: flex;align-items: center;margin-bottom:15px;">
              <label
                  class="label"
                  style="width:200px;margin-bottom: 0;"
              >Font Color</label
              >
              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="btnColorCart"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="colorpicker"
                      :style="
                      `background-color:${content.styles.product.btnColorCart}`
                    "
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="content.styles.product.btnColorCart"
                        @input="btnColorCart = false"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display: flex;align-items: center;margin-bottom:15px;">
              <label
                  class="label"
                  style="width:200px;margin-bottom: 0;"
              >Background Color</label
              >
              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="btnBgCart"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="colorpicker"
                      :style="
                      `background-color:${content.styles.product.btnBgCart}`
                    "
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="content.styles.product.btnBgCart"
                        @input="btnBgCart = false"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display: flex;align-items: center;margin-bottom:15px;">
              <label
                  class="label"
                  style="width:200px;margin-bottom: 0;"
              >Border Color</label
              >
              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="btnBorderCart"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="colorpicker"
                      :style="
                      `background-color:${content.styles.product.btnBorderCart}`
                    "
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="content.styles.product.btnBorderCart"
                        @input="btnBorderCart = false"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <v-tabs
                v-model="activeLang"
            >
              <v-tab
                  v-for="(selected_lang, i) in landing.advanced.selected_langs"
                  :key="i"
                  @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
              >
                <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
                {{ selected_lang }}
              </v-tab>
              <v-tab-item
                  v-for="(selected_lang, i) in landing.advanced.selected_langs"
                  :key="i"
              >
                <div style="display: flex;align-items: center;margin-bottom:15px;">
                  <label
                      class="label"
                      style="width:200px;margin-bottom: 0;"
                  >Button Name</label
                  >
                  <v-text-field
                      style="max-width:250px;"
                      outlined
                      solo
                      flat
                      hide-details
                      dense
                      v-model="content.styles.product.btnNameCart[selected_lang]"
                  ></v-text-field>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>




        <div style="margin-bottom:15px;">

        <div class="control" >
          <label class="label" style="font-weight: bold;font-size:20px;">Button more settings</label>
        </div>
        <div style="padding:0 15px;">
          <div style="display: flex;align-items: center;margin-bottom:15px;">
            <label
                class="label"
                style="width:200px;margin-bottom: 0;"
            >Font Color</label
            >
            <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="btnColorMore"
            >
              <template v-slot:activator="{ on }">
                <div
                    class="colorpicker"
                    :style="
                              `background-color:${content.styles.product.btnColorMore}`
                            "
                    v-on="on"
                />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker
                      v-model="content.styles.product.btnColorMore"
                      @input="btnColorMore = false"
                      flat
                  />
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <div style="display: flex;align-items: center;margin-bottom:15px;">
            <label
                class="label"
                style="width:200px;margin-bottom: 0;"
            >Background Color</label
            >
            <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="btnBgMore"
            >
              <template v-slot:activator="{ on }">
                <div
                    class="colorpicker"
                    :style="
                              `background-color:${content.styles.product.btnBgMore}`
                            "
                    v-on="on"
                />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker
                      v-model="content.styles.product.btnBgMore"
                      @input="btnBgCart = false"
                      flat
                  />
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <div style="display: flex;align-items: center;margin-bottom:15px;">
            <label
                class="label"
                style="width:200px;margin-bottom: 0;"
            >Border Color</label
            >
            <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="btnBorderMore"
            >
              <template v-slot:activator="{ on }">
                <div
                    class="colorpicker"
                    :style="
                              `background-color:${content.styles.product.btnBorderMore}`
                            "
                    v-on="on"
                />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker
                      v-model="content.styles.product.btnBorderMore"
                      @input="btnBorderMore = false"
                      flat
                  />
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <v-tabs
              v-model="activeLang"
          >
            <v-tab
                v-for="(selected_lang, i) in landing.advanced.selected_langs"
                :key="i"
                @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
            >
              <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
              {{ selected_lang }}
            </v-tab>
            <v-tab-item
                v-for="(selected_lang, i) in landing.advanced.selected_langs"
                :key="i"
            >
              <div style="display: flex;align-items: center;margin-bottom:15px;">
                <label
                    class="label"
                    style="width:200px;margin-bottom: 0;"
                >Button Name</label
                >
                <v-text-field
                    style="max-width:250px;"
                    outlined
                    solo
                    flat
                    hide-details
                    dense
                    v-model="content.styles.product.btnNameMore[selected_lang]"
                ></v-text-field>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
        </div>
      </div>
    </v-tab-item>
    <v-tab-item style="padding-left:15px;padding-top:15px;">

      <div>
      <div style="margin-bottom: 15px;">

        <div class="control">
          <label class="label" style="font-weight: bold;font-size:20px;">Name settings</label>
        </div>
        <div style="padding:0 15px;">
          <div style="display: flex;align-items: center;margin-bottom:15px;">
            <label class="label" style="margin-bottom: 0;width:200px;">
              Font Color
            </label>
            <div class="control">
              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="show_text_color_detail"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="custom__menu--color"
                      :style="`background-color:${content.styles.productDetail.color}`"
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="content.styles.productDetail.color"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </div>
          <div style="display: flex;align-items: center;margin-bottom:15px;">
            <label class="label" style="margin-bottom: 0;width:200px;">
              Font Size <b>{{ content.styles.productDetail.fontSize }}px</b>
            </label>
            <div class="slider" style="width:80%;">
              <v-slider
                  hide-details
                  :max="40"
                  :min="10"
                  v-model="content.styles.productDetail.fontSize"
              ></v-slider>
            </div>
          </div>
          <div style="display: flex;align-items: center;margin-bottom:15px;">
            <label class="label" style="margin-bottom: 0;width:200px;">
              Font Weight <b>{{ parseInt(content.styles.productDetail.fontWeight, 10) / 10 }}%</b>
            </label>
            <div class="slider" style="width:80%;">
              <v-slider
                  hide-details
                  :max="1000"
                  :min="100"
                  v-model="content.styles.productDetail.fontWeight"
                  :step="100"
              ></v-slider>
            </div>
          </div>
          <div style="display: flex;justify-content: space-between;">
            <div style="display: flex;align-items: center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">
                Font Style <b>{{ content.styles.productDetail.fontStyle }}</b>
              </label>
              <div class="control">
                <v-radio-group hide-details row v-model="content.styles.productDetail.fontStyle">
                  <v-radio
                      v-for="(type,typeIndex) in fontStyleTypes"
                      :key="type.id"
                      :label="type.label"
                      :value="type.value"
                      :style="`margin-left:${typeIndex?'15px':0}`"
                  >
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
            <div style="display: flex;align-items: center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">
                Font Family
              </label>
              <v-select
                  flat
                  dense
                  outlined
                  hide-details
                  placeholder="Select Font Family"
                  :items="fontFamilyTypes"
                  item-text="label"
                  item-value="value"
                  class="canvas__select"
                  v-model="content.styles.productDetail.fontFamily"
                  style="max-width:250px;"
              >
                <template v-slot:selection="{ item }">
                  <div :style="`font-family:'${item.value}',sans-serif`">
                    {{ item.label }}
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <div :style="`font-family:'${item.value}',sans-serif`">
                    {{ item.label }}
                  </div>
                </template>
              </v-select>
            </div>
          </div></div>
      </div>
        <div style="margin-bottom:15px;">
          <div class="control">
            <label class="label" style="font-weight: bold;font-size:20px;">Image Settings</label>
          </div>
          <div style="padding:0 15px;">
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">
                Show Image</label>
              <v-switch color="#6ab64a" v-model="content.styles.productDetail.show_icon" hide-details>
              </v-switch>
            </div>
            <div v-if="content.styles.productDetail.show_icon" style="display: flex;align-items: center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">Height <b>{{ content.styles.productDetail.img_height }}</b> px</label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :min="10"
                    :max="600"
                    v-model="content.styles.productDetail.img_height"
                ></v-slider>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom:15px;">
          <div class="control">
            <label class="label" style="font-weight: bold;font-size:20px;">Price settings</label>
          </div>
          <div style="padding: 0 15px;">
            <div
                style="display:flex;align-items:center;margin-bottom:15px;"
            >
              <label class="label" style="margin-bottom: 0;width:200px;">
                Font Color
              </label>
              <div class="control">
                <v-menu
                    top
                    nudge-left="16"
                    nudge-bottom="98"
                    :close-on-content-click="false"
                    v-model="show_text_price_color_detail"
                >
                  <template v-slot:activator="{ on }">
                    <div
                        class="custom__menu--color"
                        :style="`background-color:${content.styles.productDetail.colorPrice}`"
                        v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                          v-model="content.styles.productDetail.colorPrice"
                          flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">
                Font Size <b>{{ content.styles.productDetail.fontSizePrice }}px</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="40"
                    :min="10"
                    v-model="content.styles.productDetail.fontSizePrice"
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">
                Font Weight <b>{{ parseInt(content.styles.productDetail.fontWeightPrice, 10) / 10 }}%</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="1000"
                    :min="100"
                    v-model="content.styles.productDetail.fontWeightPrice"
                    :step="100"
                ></v-slider>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom:15px;">
          <div class="control">
            <label class="label" style="font-weight: bold;font-size:20px;">Button cart settings</label>
          </div>
          <div style="padding:0 15px;">
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">Font Color</label
              >
              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="btnColorCart_detail"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="colorpicker"
                      :style="
                      `background-color:${content.styles.productDetail.btnColorCart}`
                    "
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="content.styles.productDetail.btnColorCart"
                        @input="btnColorCart_detail = false"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">Background Color</label
              >
              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="btnBgCart_detail"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="colorpicker"
                      :style="
                      `background-color:${content.styles.productDetail.btnBgCart}`
                    "
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="content.styles.productDetail.btnBgCart"
                        @input="btnBgCart_detail = false"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">Border Color</label
              >
              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="btnBorderCart_detail"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="colorpicker"
                      :style="
                      `background-color:${content.styles.productDetail.btnBorderCart}`
                    "
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="content.styles.productDetail.btnBorderCart"
                        @input="btnBorderCart_detail = false"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <v-tabs
                v-model="activeLang"
            >
              <v-tab
                  v-for="(selected_lang, i) in landing.advanced.selected_langs"
                  :key="i"
                  @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
              >
                <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
                {{ selected_lang }}
              </v-tab>
              <v-tab-item
                  v-for="(selected_lang, i) in landing.advanced.selected_langs"
                  :key="i"
              >
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">Button Name</label
                  >
                  <v-text-field
                      style="max-width:250px;"
                      outlined
                      solo
                      flat
                      hide-details
                      dense
                      v-model="content.styles.productDetail.btnNameCart[selected_lang]"
                  ></v-text-field>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
        <div style="margin-bottom:15px;">
          <div class="control">
            <label class="label" style="font-weight: bold;font-size:20px;">Button open cart settings</label>
          </div>
          <div style="padding:0 15px;">
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">Font Color</label>
              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="btnColorOpenCart_detail"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="colorpicker"
                      :style="
                      `background-color:${content.styles.productDetail.btnColorOpenCart}`
                    "
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="content.styles.productDetail.btnColorOpenCart"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">Background Color</label
              >
              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="btnBgOpenCart_detail"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="colorpicker"
                      :style="
                      `background-color:${content.styles.productDetail.btnBgOpenCart}`
                    "
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="content.styles.productDetail.btnBgOpenCart"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">Border Color</label
              >
              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="btnBorderOpenCart_detail"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="colorpicker"
                      :style="
                      `background-color:${content.styles.productDetail.btnBorderOpenCart}`
                    "
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="content.styles.productDetail.btnBorderOpenCart"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <v-tabs
                v-model="activeLang"
            >
              <v-tab
                  v-for="(selected_lang, i) in landing.advanced.selected_langs"
                  :key="i"
                  @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
              >
                <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
                {{ selected_lang }}
              </v-tab>
              <v-tab-item
                  v-for="(selected_lang, i) in landing.advanced.selected_langs"
                  :key="i"
              >
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">Button Name</label
                  >
                  <v-text-field
                      style="max-width:250px;"
                      outlined
                      solo
                      flat
                      hide-details
                      dense
                      v-model="content.styles.productDetail.btnNameOpenCart[selected_lang]"
                  ></v-text-field>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
        <div style="margin-bottom:15px;">
          <div class="control" >
            <label class="label" style="font-weight: bold;font-size:20px;">Recommended/Similar settings</label>
          </div>
          <div style="padding: 0 15px;">
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">
                Width <b>{{  content.styles.productDetail.slider.width }}px</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="1000"
                    :min="100"
                    v-model="content.styles.productDetail.slider.width"
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">
                Height <b>{{  content.styles.productDetail.slider.height }}px</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="500"
                    :min="100"
                    v-model="content.styles.productDetail.slider.height"
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">
                Image Height <b>{{  content.styles.productDetail.slider.img_height }}px</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="500"
                    :min="100"
                    v-model="content.styles.productDetail.slider.img_height"
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">Background Color</label
              >
              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="slider_background"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="colorpicker"
                      :style="
                      `background-color:${content.styles.productDetail.slider.background}`
                    "
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="content.styles.productDetail.slider.background"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="margin-bottom: 0;width:200px;">Border Color</label
              >
              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="slider_border_color"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="colorpicker"
                      :style="
                      `background-color:${content.styles.productDetail.slider.border_color}`
                    "
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                        v-model="content.styles.productDetail.slider.border_color"
                        flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
            <div>
              <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Name settings</label>
              </div>
              <div style="padding:0 15px;">
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">
                    Font Color
                  </label>
                  <div class="control">
                    <v-menu
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        :close-on-content-click="false"
                        v-model="show_name_color_slider"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                            class="custom__menu--color"
                            :style="`background-color:${content.styles.productDetail.slider.name.color}`"
                            v-on="on"
                        />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                              v-model="content.styles.productDetail.slider.name.color"
                              flat
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">
                    Font Size <b>{{ content.styles.productDetail.slider.name.font_size }}px</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="40"
                        :min="10"
                        v-model="content.styles.productDetail.slider.name.font_size"
                    ></v-slider>
                  </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">
                    Font Weight <b>{{ parseInt(content.styles.productDetail.slider.name.font_weight, 10) / 10 }}%</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="1000"
                        :min="100"
                        v-model="content.styles.productDetail.slider.name.font_weight"
                        :step="100"
                    ></v-slider>
                  </div>
                </div>
                <div style="display: flex;justify-content: space-between;">
                  <div style="display:flex;align-items:center;margin-bottom:15px;">
                    <label class="label" style="margin-bottom: 0;width:200px;">
                      Font Style <b>{{ content.styles.productDetail.slider.name.font_style }}</b>
                    </label>
                    <div class="control">
                      <v-radio-group hide-details row v-model="content.styles.productDetail.slider.name.font_style">
                        <v-radio
                            v-for="(type,typeIndex) in fontStyleTypes"
                            :key="type.id"
                            :label="type.label"
                            :value="type.value"
                            :style="`margin-left:${typeIndex?'15px':0}`"
                        >
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <div style="display:flex;align-items:center;margin-bottom:15px;">
                    <label class="label" style="margin-bottom: 0;width:200px;">
                      Font Family
                    </label>
                    <v-select
                        flat
                        dense
                        outlined
                        hide-details
                        placeholder="Select Font Family"
                        :items="fontFamilyTypes"
                        item-text="label"
                        item-value="value"
                        class="canvas__select"
                        v-model="content.styles.productDetail.slider.name.font_family"
                        style="max-width:250px;"
                    >
                      <template v-slot:selection="{ item }">
                        <div :style="`font-family:'${item.value}',sans-serif`">
                          {{ item.label }}
                        </div>
                      </template>
                      <template v-slot:item="{ item }">
                        <div :style="`font-family:'${item.value}',sans-serif`">
                          {{ item.label }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Title settings</label>
              </div>
              <div style="padding: 0 15px;">
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">
                    Font Color
                  </label>
                  <div class="control">
                    <v-menu
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        :close-on-content-click="false"
                        v-model="show_title_color_slider"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                            class="custom__menu--color"
                            :style="`background-color:${content.styles.productDetail.slider.title.color}`"
                            v-on="on"
                        />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                              v-model="content.styles.productDetail.slider.title.color"
                              flat
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">
                    Font Size <b>{{ content.styles.productDetail.slider.title.font_size }}px</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="40"
                        :min="10"
                        v-model="content.styles.productDetail.slider.title.font_size"
                    ></v-slider>
                  </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">
                    Font Weight <b>{{ parseInt(content.styles.productDetail.slider.title.font_weight, 10) / 10 }}%</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="1000"
                        :min="100"
                        v-model="content.styles.productDetail.slider.title.font_weight"
                        :step="100"
                    ></v-slider>
                  </div>
                </div>
                <div style="display: flex;justify-content: space-between;">
                  <div style="display:flex;align-items:center;margin-bottom:15px;">
                    <label class="label" style="margin-bottom: 0;width:200px;">
                      Font Style <b>{{ content.styles.productDetail.slider.title.font_style }}</b>
                    </label>
                    <div class="control">
                      <v-radio-group hide-details row v-model="content.styles.productDetail.slider.title.font_style">
                        <v-radio
                            v-for="(type,typeIndex) in fontStyleTypes"
                            :key="type.id"
                            :label="type.label"
                            :value="type.value"
                            :style="`margin-left:${typeIndex?'15px':0}`"
                        >
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <div style="display:flex;align-items:center;margin-bottom:15px;">
                    <label class="label" style="margin-bottom: 0;width:200px;">
                      Font Family
                    </label>
                    <v-select
                        flat
                        dense
                        outlined
                        hide-details
                        placeholder="Select Font Family"
                        :items="fontFamilyTypes"
                        item-text="label"
                        item-value="value"
                        class="canvas__select"
                        v-model="content.styles.productDetail.slider.title.font_family"
                        style="margin-bottom: 15px;max-width:250px;"
                    >
                      <template v-slot:selection="{ item }">
                        <div :style="`font-family:'${item.value}',sans-serif`">
                          {{ item.label }}
                        </div>
                      </template>
                      <template v-slot:item="{ item }">
                        <div :style="`font-family:'${item.value}',sans-serif`">
                          {{ item.label }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-tab-item>
  </v-tabs>
</div>
</template>

<script>
import {mapState} from "vuex";

export default {
name: "ProductMenuProductStyles",
  props:['content'],
  methods:{
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
  },
  watch:{

  },
  computed:{
    ...mapState(['landing']),
    activeLang:{
      set:function(val){

      },
      get:function(){
        return this.landing.advanced.selected_langs.indexOf(this.landing.current_lang)
      }
    },
  },
  data(){
  return{
    show_title_color_slider:false,
    show_name_color_slider:false,
    show_price_color_slider:false,
    slider_background:false,
    slider_border_color:false,
    btnColorOpenCart:false,
    btnBgOpenCart:false,
    btnBorderOpenCart:false,
    btnColorOpenCart_detail:false,
    btnBgOpenCart_detail:false,
    btnBorderOpenCart_detail:false,
    show_text_color_detail:false,
    show_text_price_color_detail: false,
    btnColorCart_detail:false,
    btnBorderCart_detail:false,
    btnBgCart_detail:false,
    active_tab:0,
    show_text_color: false,
    show_text_price_color: false,
    btnColorCart:false,
    btnBorderCart:false,
    btnBgCart:false,
    btnColorMore:false,
    btnBorderMore:false,
    btnBgMore:false,
    fontStyleTypes: [
      { id: 1, value: "normal", label: "Normal" },
      { id: 0, value: "italic", label: "Italic" },
    ],
    fontFamilyTypes: [
      { id: 0, value: "Arial", label: "Arial" },
      { id: 1, value: "SF UI Text", label: "SF UI Text" },
      { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
      { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
      { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
      { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
      { id: 6, value: "Geometria", label: "Geometria" },
      { id: 7, value: "DIN Pro", label: "DIN Pro" },
      { id: 8, value: "Circe", label: "Circe" },
      { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
      { id: 10, value: "Acrom", label: "Acrom" },
      { id: 11, value: "Inter", label: "Inter" },
    ],
    positionTypes: [
      { id: 0, valueHorizontal: "flex-start" },
      { id: 1, valueHorizontal: "center" },
      { id: 2, valueHorizontal: "flex-end" },
    ],
  }
  }
}
</script>

<style scoped>

</style>
