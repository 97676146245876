<template>
  <div>
    <div style="display: flex;align-items: center;margin-bottom: 15px;"
         :style="`justify-content:${styles?'space-between':'flex-end'};`">
      <div v-if="styles" style="display: flex; align-items: center;">
        <v-btn color="primary" outlined @click="openStyles" small
        ><v-icon>mdi-chevron-left</v-icon>
          <span>Back to subcategories list</span></v-btn
        >
      </div>
      <v-menu offset-y open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              outlined
              small
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title style="cursor: pointer"
                               @click="openStyles"
            > {{styles ?'Content settings' : 'Style settings'}}</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="cursor: pointer"
                               @click="$emit('OpenCartSettings' )"
            >Cart settings</v-list-item-title
            >
          </v-list-item>
            <v-list-item>
                <v-list-item-title
                        @click="openUploadCSV"
                        style="cursor: pointer;"
                >Upload CSV</v-list-item-title
                >
            </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div
      v-if="!styles"
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      "
    >
      <div>
        <v-btn @click="$emit('openCategory')" small color="primary" outlined
          ><v-icon>mdi-chevron-left</v-icon>

          <span>
            Back to categories
          </span>
        </v-btn
        >
      </div>
      <v-btn
        class="object__submit btn btn_primary"
        style="float: right"
        color="primary"
        outlined
        small
        solo
        @click="createSubCategory"
      >
        Create subcategory
      </v-btn>
    </div>

    <div style="margin-bottom: 15px" v-if="!styles">
      <v-data-table
        :headers="headersSubCategories"
        hide-default-footer
        :items="listSubcategories"
        class="table"
      >
        <template v-slot:body="{ items }">
          <tbody v-if="items.length">
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell" style="text-align: center">{{ item.id }}</td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                {{ item.name[landing.current_lang] }}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center;cursor: pointer;" @click="$emit('openDialogProductsList', item)">
                {{ item.products.length }}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                <v-chip
                  class="ma-2"
                  :color="item.active ? 'green' : 'red'"
                  text-color="white"
                  style="cursor: pointer"
                  @click="changeSubCategoryStatus(item)"
                >
                  {{ item.active ? "Active" : "Inactive" }}
                </v-chip>
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                <v-menu offset-y open-on-hover bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined color="primary" dark small v-bind="attrs" v-on="on">
                      <v-icon>mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      style="cursor: pointer"
                      @click="showSubcategoryEdit(item)"
                    >
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        @click="openDeleteSubCategoryPopup(item)"
                        style="cursor: pointer; color: red"
                        >Delete</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item v-if="!item.pdf_file">
                      <v-list-item-title
                        style="cursor: pointer"
                        @click="$emit('openDialogProductsList', item)"
                      >
                        List products
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
          <tbody v-if="items.length === 0">
            <tr>
              <td class="d-block d-sm-table-cell"
                colspan="6"
                style="text-align: center; padding: 0 15px; font-weight: normal"
              >
                You don't have subcategories yet, it's time to create them
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <div v-if="styles">
      <ProductMenuSubcategoryStyles :content="content" />
    </div>
    <!--Create new subcategory dialog-->
    <v-dialog :fullscreen="$store.state.isMobile"
      v-model="dialogCreateSubCategory"
      max-width="750"
      @click:outside="closeDialogCreateSubCategory"
    >
      <v-card>
        <v-card-title
          ><span>Create subcategory</span>
          <v-icon
            style="position: absolute; top: 10px; right: 10px"
            @click="closeDialogCreateSubCategory"
            >mdi-close</v-icon
          ></v-card-title
        >
        <div style="padding: 30px">
          <v-tabs

            v-model="activeLang"
            style="margin-bottom: 30px"
          >
            <v-tab
              v-for="(lang, i) in landing.selected_langs"
              :key="i"
              @change="changeLang(lang)"
            >
              <img
                :src="landing.advanced.langs.find((x) => x.value === lang).flag"
                alt=""
                style="width: 20px; height: 15px; margin-right: 5px"
              />
              {{ lang }}
            </v-tab>
            <v-tab-item
              style="padding-top: 15px"
              v-for="(lang, i) in landing.selected_langs"
              :key="i"
            >
              <div class="label" style="margin: 15px 0">Subcategory name</div>
              <v-text-field
                outlined
                dense
                style="max-width: 50%"
                hide-details
                v-model="createdSubCategory.name[lang]"
                placeholder="Your category name"
              ></v-text-field>
            </v-tab-item>
          </v-tabs>
            <div style="margin-bottom: 15px;">

                <div class="label" style="margin-bottom: 15px">Subcategory hidden id</div>
                <v-text-field
                        outlined
                        dense
                        style="max-width: 50%;"
                        hide-details
                        v-model="createdSubCategory.hidden_id"
                        placeholder="Your subcategory hidden id"
                ></v-text-field>
            </div>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-evenly;
            "
          >
            <div style="flex: 1; margin-bottom: 15px">
              <div class="label" style="margin-bottom: 10px">
                Choose your image
              </div>
              <vue-dropzone
                id="createSubDropzone"
                :options="dropzoneOptions"
                :destroyDropzone="false"
                ref="createSubDropzone"
                @vdropzone-sending="customEvent"
                @vdropzone-success="handleSuccessCreateSubCategory"
              >
              </vue-dropzone>
            </div>
            <div
              style="
                flex: 1;
                text-align: center;
                font-weight: bold;
                font-size: 22px;
                margin-bottom: 15px;
              "
            >
              or
            </div>
            <div class="control" style="flex: 1;position:relative;">
              <div @click="showIcons = !showIcons">
              <label class="label">Choose your main as icon</label>
              <v-select
                  style="z-index: 21;width:220px"
                  outlined
                  solo
                  flat
                  dense
                  :menu-props="{ contentClass: 'my-awesome-list' }"
                  :items="icons.data.map((item) => item.url)"
                  :value="createdSubCategory.main_file"
                  v-model="createdSubCategory.main_file"

              >
                <template v-slot:selection="{ item }">
                  <img class="icon" :src="item" />
                </template>
                <template v-slot:item="{ item }">
                  <img class="icon" :src="item" />
                </template>
              </v-select>
              </div>
              <div v-if="showIcons" style="width:220px;height:150px;background:#fff;border:1px solid #ccc;position: absolute;top:74px;z-index: 100;display:flex;flex-wrap: wrap;overflow-y:scroll;overflow-x:hidden;">
                <div v-for="icon in icons.data.map((item) => item.url)" :key="icon"  style="height: 40px;width: 40px;padding:5px;" :style="`${createdSubCategory.main_file === icon?'border:2px solid #007db5;background:#ddd;':'' };`">
                  <img :src="icon" :alt="icon" style="height: 100%;width: 100%;cursor: pointer;" @click="handleChange(icon)">
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-bottom: 15px">
            <label class="label" style="margin-right: 15px"
              >Upload pdf to subcategory</label
            >
            <v-switch color="#6ab64a" v-model="showSubcategoryFile" hide-details> </v-switch>
          </div>
          <div style="margin-bottom: 15px" v-if="showSubcategoryFile">
            <div class="label" style="margin-bottom: 10px">
              Choose your subcategory file
            </div>
            <v-file-input
              accept="application/pdf"
              outlined
              style="max-width: 50%;"
              dense
              ref="pdfUpload"
              prepend-inner-icon="mdi-file"
              prepend-icon=""
              @change="(file) => onFileChange(file, createdSubCategory)"
            ></v-file-input>
          </div>
        </div>
        <v-card-actions class="justify-end">
          <v-btn
            small
            outlined
            style="margin-bottom: 10px; max-width: 360px"
            color="primary"
            @click="createNewSubCategory"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Create new subcategory dialog-->
    <!--Edit subcategory dialog-->
    <v-dialog :fullscreen="$store.state.isMobile"
      v-model="dialogEditSubCategory"
      max-width="750"
      @click:outside="closeDialogEditSubCategory"
    >
      <v-card>
        <v-card-title
        ><span>Edit subcategory</span>
          <v-icon
                  style="position: absolute; top: 10px; right: 10px"
                  @click="closeDialogEditSubCategory"
          >mdi-close</v-icon
          ></v-card-title
        >
        <div style="padding: 30px; overflow: auto">
          <v-tabs  v-model="activeLang" style="margin-bottom: 30px;">
            <v-tab
                    v-for="(lang, i) in landing.selected_langs"
                    :key="i"
                    @change="changeLang(lang)"
            >
              <img
                      :src="landing.advanced.langs.find((x) => x.value === lang).flag"
                      alt=""
                      style="width: 20px; height: 15px; margin-right: 5px"
              />
              {{ lang }}
            </v-tab>
            <v-tab-item
                    style="padding-top: 15px"
                    v-for="(lang, i) in landing.selected_langs"
                    :key="i"
            >
              <div class="label" style="margin-bottom: 15px">Subcategory name</div>
              <v-text-field
                      outlined
                      dense
                      style="max-width: 50%;"
                      hide-details
                      v-model="editSubCategory.name[lang]"
                      placeholder="Your subcategory name"
              ></v-text-field>
            </v-tab-item>
          </v-tabs>
<div style="margin-bottom: 15px;">

    <div class="label" style="margin-bottom: 15px">Subcategory hidden id</div>
    <v-text-field
            outlined
            dense
            style="max-width: 50%;"
            hide-details
            v-model="editSubCategory.hidden_id"
            placeholder="Your subcategory hidden id"
    ></v-text-field>
</div>
          <div
                  style="
              display: flex;
              align-items: center;
              justify-content: space-evenly;
            "
          >
            <div style="flex:1;margin-bottom: 15px">
              <div class="label" style="margin-bottom: 15px">
                Choose your image
              </div>
              <vue-dropzone
                id="editSubcategory"
                :options="dropzoneOptions"
                :destroyDropzone="false"
                ref="editSubcategory"
                @vdropzone-mounted="mountedEditSubcategoryDropzone"
                @vdropzone-sending="customEvent"
                @vdropzone-success="handleSuccessEditSubCategory"
              >
              </vue-dropzone>
            </div>
            <div style="flex:1;text-align:center;font-weight: bold; font-size: 22px; margin-bottom: 15px">
              or
            </div>
            <div class="control" style="flex: 1;position:relative;">
              <div @click="showIconsEdit = !showIconsEdit">
                <label class="label">Choose your main as icon</label>
                <v-select
                    style="z-index: 21;width:220px"
                    outlined
                    solo
                    flat
                    dense
                    :menu-props="{ contentClass: 'my-awesome-list' }"
                    :items="icons.data.map((item) => item.url)"
                    :value="editSubCategory.main_file"
                    v-model="editSubCategory.main_file"

                >
                  <template v-slot:selection="{ item }">
                    <img class="icon" :src="item" />
                  </template>
                  <template v-slot:item="{ item }">
                    <img class="icon" :src="item" />
                  </template>
                </v-select>
              </div>
              <div v-if="showIconsEdit" style="width:220px;height:150px;background:#fff;border:1px solid #ccc;position: absolute;top:74px;z-index: 100;display:flex;flex-wrap: wrap;overflow-y:scroll;overflow-x:hidden;">
                <div v-for="icon in icons.data.map((item) => item.url)" :key="icon"  style="height: 40px;width: 40px;padding:5px;" :style="`${editSubCategory.main_file === icon?'border:2px solid #007db5;background:#ddd;':'' };`">
                  <img :src="icon" :alt="icon" style="height: 100%;width: 100%;cursor: pointer;" @click="handleChangeEdit(icon)">
                </div>
              </div>
            </div>

          </div>
          <div style="display: flex; align-items: center; margin-bottom: 15px">
            <label class="label" style="margin-right: 15px"
              >Upload pdf to subcategory</label
            >
            <v-switch color="#6ab64a" v-model="showSubcategoryFile" hide-details> </v-switch>
          </div>
          <div v-if="showSubcategoryFile" style="margin-bottom: 15px">
            <div class="label" style="margin-bottom: 10px">
              Choose your category file
            </div>
            <v-file-input
              accept="application/pdf"
              outlined
              dense
              ref="pdfUpload"
              prepend-inner-icon="mdi-file"
              prepend-icon=""
              v-model="editSubCategoryFilePdf"
              @change="(file) => onFileChange(file, editSubCategory)"
            ></v-file-input>
          </div>
        </div>
        <v-card-actions class="justify-end">
          <v-btn
            small
            outlined
            style=" margin-bottom: 10px; max-width: 360px"
            color="primary"
            @click="editSubCategorySuccess"
            >Edit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Edit subcategory dialog-->
    <PopupSuccess ref="PopupSuccess" :title="'Thank you!'" :content="successMessage" />
    <PopupError :title="error" ref="PopupError" />
    <PopupDelete
      ref="PopupDeleteSubCategory"
      title="Are your sure about delete subcategory?"
      @confirm="deleteSubCategory"
    />
    <v-dialog :fullscreen="$store.state.isMobile" v-model="uploadCSV" max-width="800px">
        <v-card>
            <v-icon
                    style="top: 10px; right: 10px; position: absolute"
                    class="close__modal"
                    @click="closeUploadCSV"
            >mdi-close</v-icon
            >
            <v-card-title>Upload csv data</v-card-title>
            <v-card-text>
                <div>
                    <label class="label" style="margin: 15px 0; display: block"
                    >File uploader
                    </label>
                </div>
                <div>
                    <v-btn
                            color="primary"
                            outlined
                            small
                            :loading="isSelecting"
                            @click="handleFileImport"
                    >
                        Upload File
                    </v-btn>
                    {{ selectedFile ? selectedFile.name : "" }}
                </div>
                <v-file-input
                        accept="text/csv"
                        show-size
                        dense
                        prepend-icon=""
                        ref="uploader"
                        class="d-none"
                        outlined
                        @change="onFileChangeCSV"
                ></v-file-input>
                <div style="max-width: 500px; margin-top: 15px">
                    To add display buttons on the detail page of the listing, use:
                    <br />
                    [button=https:/tourist.com]Tourist[/button]<br />

                    To add an active link, use<br />
                    [url=https://tourist.com]Tourist[/url]<br /><br />

                    We have prepared for you a sample csv table, which you can
                    <a href="https://tourist.hr">get here</a>
                </div>
            </v-card-text>
            <v-card-actions
                    style="padding-bottom: 15px; display: flex; justify-content: flex-end"
            >
                <v-btn
                        @click="openUploadImageDialog"
                        color="primary"
                        outlined
                        dense
                        small
                        style="max-width: 100px; align-self: flex-end"
                >
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="uploadImage" max-width="800px">
          <v-card>
              <v-icon
                      style="top: 10px; right: 10px; position: absolute"
                      class="close__modal"
                      @click="closeUploadImageDialog"
              >mdi-close</v-icon
              >
              <v-card-title>Upload images</v-card-title>
              <v-card-text>
                  <label class="label" style="display: block; margin-bottom: 15px"
                  >We accepted .jpg .png .gif .svg images type files</label
                  ><br />
                  <label class="label" style="display: block; margin-bottom: 15px"
                  >We found in your file items of category: {{ listingCount }}</label
                  >
                  <vue-dropzone
                          :id="`uploadCSV`"
                          :ref="`uploadCSV`"
                          :options="dropzoneOptionsUploadCSV"
                          style="margin-bottom: 15px"
                          maxFiles="100"
                          :destroyDropzone="false"
                          @vdropzone-sending="customEventUploadCSV"
                          @vdropzone-success="handleSuccessUploadCSV"
                  >
                  </vue-dropzone>
                  <label class="label" style="display: block; margin-bottom: 15px"
                  >We uploaded {{ countOfImages - uploadedImages.length }} of
                      {{ countOfImages }} images.</label
                  >
                  <label class="label" style="display: block; margin-bottom: 15px"
                  >We are waiting for pictures with names:</label
                  >
                  <div
                          v-for="img in uploadedImages"
                          :key="img + 'uploaded'"
                          style="margin-right: 15px"
                  >
                      {{ img }}
                  </div>
              </v-card-text>
              <v-card-actions
                      style="padding-bottom: 15px; display: flex; justify-content: flex-end"
              >
                  <v-btn
                          @click="completeUploadCSV"
                          color="primary"
                          outlined
                          dense
                          small
                          style="max-width: 100px; align-self: flex-end"
                  >
                      Confirm
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </div>
</template>

<script>
import vueDropzone from "vue2-dropzone";
import { mapState } from "vuex";
import PopupSuccess from "@/components/Popup/PopupSuccess.vue";
import PopupError from "@/components/Popup/PopupError.vue";
import PopupDelete from "@/components/Popup/PopupDelete.vue";
import ProductMenuSubcategoryStyles from "./ProductMenuSubcategoryStyles.vue";
import moment from "moment";
import ImagesService from "../../../../services/images.service";
export default {
  name: "ProductMenuSubcategory",
  components: {
    vueDropzone,
    PopupSuccess,
    PopupError,
    PopupDelete,
    ProductMenuSubcategoryStyles,
  },
  props: ["content", "selectedCategory", "listSubcategories","selectedMenuID"],
  computed: {
    ...mapState(["landing"]),
    ...mapState("landing", ["icons"]),
    ...mapState("products", ["categories"]),
    activeLang: {
      set: function (val) {},
      get: function () {
        return this.landing.advanced.selected_langs.indexOf(
          this.landing.current_lang
        );
      },
    },
  },
  data() {
    return {
      showIcons:false,
      showIconsEdit:false,
        countOfImages: 0,
        uploadedImages: [],
        uploadImage: false,
        isSelecting: false,
        uploadCSV: false,
        selectedFile: null,
        uploadedData: [],
        dropzoneOptionsUploadCSV: {
            url: () => {
                return process.env.VUE_APP_API_URL + "upload-image";
            },
            thumbnailMethod: "contain",
            acceptedFiles: "image/*",
            addRemoveLinks: true,
            maxFilesize: 3,
            maxFiles: 300,
            destroyDropzone: false,
        },
        listingCount:0,
      headersSubCategories: [
        { text: "ID", value: "id", align: "center", sortable: true },
        { text: "Name", value: "name", align: "center", sortable: true },
        {
          text: "Product count",
          value: "products",
          align: "center",
          sortable: true,
        },
        {
          text: "Status",
          value: "active",
          align: "center",
          sortable: true,
        },
        { text: "Options", align: "center", sortable: true },
      ],
      dialogCreateSubCategory: false,
      dialogEditSubCategory: false,
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        maxfiles: 3,
        thumbnailMethod: "contain",
        acceptedfiles: "image/*",
        addRemoveLinks: true,
      },
      createdSubCategory: {
        name: { en: "" },
        files: [],
        hotel_id: "",
        main_file: "https://api.guesteco.com/storage/default-landing-icons/27.svg",
        pdf_file: "",
          hidden_id:null,
        active: 1,
        product_category_id: 0,
        order:0
      },
      defaultSubCategory: {
        name: { en: "" },
        files: [],
        hotel_id: "",
        main_file: "https://api.guesteco.com/storage/default-landing-icons/27.svg",
        pdf_file: "",
        hidden_id:null,
        active: 1,
        product_category_id: 0,
        order:0
      },
      editSubCategory: {
        name: { en: "" },
        files: [],
        product_category_id: "",
        main_file: "https://api.guesteco.com/storage/default-landing-icons/27.svg",
        pdf_file: "",
        active: 1,
          hidden_id:null,
        menu_id: "",
        order:0
      },
      editSubCategoryFilePdf: [],
      successMessage: "",
      error: "",
      styles: false,
      deleteSubCategoryItem: null,
      showSubcategoryFile: false,
      showSubcategoryIcon: false,
    };
  },
  methods: {
    handleChange(value) {
      this.showIcons = false;
      this.createdSubCategory.main_file = value;
    },
    handleChangeEdit(value) {
      this.showIconsEdit = false;
      this.editSubCategory.main_file = value;
    },
      normalizeImg(img){
          if(img){
              return img.replaceAll(' ','').replaceAll('&','').replaceAll('-','').toLowerCase().replaceAll('jpeg','jpg').replaceAll('š','s').replaceAll('ć','c').replaceAll('š','s').replaceAll('ć','c');
          }else{
              return ""
          }
      },
      async onFileChangeCSV(file) {
          this.selectedFile = file;
          this.uploadedImages = [];
          this.$papa.parse(file, {
              complete: async (result) => {
                  for (let i = 1; i < result.data.length; i++) {
                      const img = result.data[i][1];
                      const candidate = this.uploadedData.find(x => result.data[i][2] && x.hidden_id === result.data[i][2]);
                      if(candidate){
                          candidate.name[result.data[i][3]] = result.data[i][0];
                      }else{
                          const category = {
                              name: {},
                              files: [],
                              product_category_id:this.selectedCategory.id,
                              main_file: img,
                              pdf_file: "",
                              active: 1,
                              hidden_id:null
                          };
                          if(result.data[i][2]){
                              category.hidden_id = result.data[i][2];
                          }
                          category.name[result.data[i][3]] = result.data[i][0];
                          this.uploadedData.push(category);
                      }
                      if (
                          !this.uploadedImages.includes(this.normalizeImg(img)) &&
                          !img.includes("guesteco.com") && img
                      ) {
                          this.uploadedImages.push(this.normalizeImg(img));
                      }
                  }
                  this.listingCount = this.uploadedData.length;
                  this.countOfImages = this.uploadedImages.length;
              },
          });
      },

      async completeUploadCSV() {
          for (let i = 0; i < this.uploadedData.length; i++) {
              if (
                  !this.uploadedData[i].main_file.includes("guesteco.com")
              ) {
                  this.uploadedData[i].main_file = "";
              }
              const {data} = await this.$axios.post(
                  `${process.env.VUE_APP_API_URL}product-sub-category`,
                  this.uploadedData[i]
              );
                  this.selectedCategory.sub_categories.push(data);
          }
          this.successMessage = "New subcategories successfully uploaded!";
          this.$refs.PopupSuccess.open();
          this.uploadedData = [];
          this.uploadImage = false;
      },
      handleSuccessUploadCSV(file) {
          let trigger = true;
          for (let i = 0; i < this.uploadedData.length; i++) {
              if (
                  this.normalizeImg(this.uploadedData[i].main_file) ===
                  this.normalizeImg(file.name)
              ) {
                  this.uploadedData[i].main_file = file.xhr.response;
                  if (this.uploadedImages.includes(this.normalizeImg(file.name))) {
                      this.uploadedImages.splice(
                          this.uploadedImages.indexOf(this.normalizeImg(file.name)),
                          1
                      );
                      trigger = false;
                      break
                  }
              }
          }
          if (trigger) {
              this.$refs.uploadCSV.removeFile(file);
          }
      },
      customEventUploadCSV(_file, xhr, formData) {
          xhr.setRequestHeader(
              "Authorization",
              `Bearer ${this.$store.state.token}`
          );
          formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
          );
          formData.append("image", _file);
      },
      openUploadImageDialog() {
          this.uploadImage = true;
          this.uploadCSV = false;
      },
      closeUploadImageDialog() {
          this.uploadImage = false;
      },
      handleFileImport() {
          this.isSelecting = true;
          window.addEventListener(
              "focus",
              () => {
                  this.isSelecting = false;
              },
              { once: true }
          );
          this.$refs.uploader.$refs.input.click();
      },
      async downloadImages() {
          let images = [];
          for (let i = 0; i < this.content.content.listing.length; i++) {
              for (
                  let index = 0;
                  index < this.content.content.listing[i].content.length;
                  index++
              ) {
                  let item = this.content.content.listing[i].content[index];
                  if (item.main_img) {
                      let id = this.getImageId(item.main_img);
                      if (!images.includes(id)) {
                          images.push(id);
                      }
                  }
                  if (item.img.length) {
                      for (
                          let imageIndex = 0;
                          imageIndex < item.img.length;
                          imageIndex++
                      ) {
                          let id = this.getImageId(item.img[imageIndex]);
                          if (!images.includes(id)) {
                              images.push(id);
                          }
                      }
                  }
              }
          }
          if (images.length) {
              const response = await ImagesService.getZipByImagesID(images);
              if (response.status === 201) {
                  const link = document.createElement("a");
                  link.href = response.data;
                  const evt = new MouseEvent("click", {
                      view: window,
                      bubbles: true,
                      cancelable: true,
                  });
                  link.dispatchEvent(evt);
                  link.remove();
              }
          } else {
              this.$refs.PopupError.open();
          }
      },
      closeUploadCSV() {
          this.uploadCSV = false;
      },
      openUploadCSV() {
          this.uploadedData = [];
          this.uploadCSV = true;
      },
    formatDate(date) {
      return moment(date).format("DD.MM.yyyy HH:mm");
    },
    async showSubcategoryEdit(item) {
      this.editSubCategory = JSON.parse(JSON.stringify(item));
      if (this.editSubCategory.pdf_file) {
        this.showSubcategoryFile = true;
        if (typeof this.editSubCategory.pdf_file === "string") {
          this.editSubCategoryFilePdf = await this.createFile(
            this.editSubCategory.pdf_file
          );
        }
      } else {
        this.showSubcategoryFile = false;
      }
      let mockFile = {
        accepted: true,
        cropped: true,
        kind: "image",
        ...this.editSubCategory,
      };
      if (this.$refs.editSubcategory) {
        let elements =
          this.$refs.editSubcategory.$el.getElementsByClassName("dz-preview");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
        for (let i = 0; i < this.editSubCategory.files.length; i++) {
          mockFile.removeItemIndex = i;
          this.$refs.editSubcategory.manuallyAddFile(
            mockFile,
            this.editSubCategory.files[i]
          );
          this.$refs.editSubcategory.dropzone.emit(
            "thumbnail",
            mockFile,
            this.editSubCategory.files[i]
          );
          this.$refs.editSubcategory.dropzone.emit("complete", mockFile);
        }
      }
      this.dialogEditSubCategory = true;
    },
    checkIconOrImage(link) {},
    openDeleteSubCategoryPopup(item) {
      this.deleteSubCategoryItem = item;
      this.$refs.PopupDeleteSubCategory.open();
    },
    async deleteSubCategory() {
      try {
        const response = await this.$axios.delete(
          `product-sub-category/${this.deleteSubCategoryItem.id}`
        );
        if (response.status === 201) {
          this.selectedCategory.sub_categories.splice(
            this.selectedCategory.sub_categories.indexOf(
              this.deleteSubCategoryItem
            ),
            1
          );
          this.successMessage = response.data;
          this.$refs.PopupSuccess.open();
        } else {
          this.successMessage = "Something going wrong, try again please!";
          this.$refs.PopupSuccess.open();
        }
      } catch (e) {
        this.error = e.message;
        this.$refs.PopupError.open();
      }
    },
    async changeSubCategoryStatus(item) {
      try {
        if (item.active) {
          item.active = 0;
        } else {
          item.active = 1;
        }
        await this.$axios.put(`/product-sub-category/${item.id}`, item);
      } catch (e) {
        this.error = e.message;
        this.$refs.PopupError.open();
      }
    },
    createSubCategory(item) {
      this.createdSubCategory = JSON.parse(JSON.stringify(this.defaultSubCategory));
      this.dialogCreateSubCategory = true;
      this.showSubcategoryFile = false;
    },
    openStyles() {
      this.styles = !this.styles;
    },
    async editSubCategorySuccess() {
      try {
        const response = await this.$axios.put(
          `/product-sub-category/${this.editSubCategory.id}`,
          this.editSubCategory
        );
        const candidate = this.selectedCategory.sub_categories.find(
          (x) => x.id === this.editSubCategory.id
        );
        if (candidate) {
          this.selectedCategory.sub_categories.splice(
            this.selectedCategory.sub_categories.indexOf(candidate),
            1,
            response.data
          );
        }
        this.successMessage = "Your subcategory edited successful!";
        this.dialogEditSubCategory = false;
        this.editSubCategoryFilePdf = [];
        this.$refs.PopupSuccess.open();
      } catch (e) {
        this.error = e.message;
        this.$refs.PopupError.open();
      }
    },
    handleSuccessEditSubCategory(file) {
      if (this.editSubCategory.files.length === 0) {
        this.editSubCategory.main_file = file.xhr.response;
      }
      this.editSubCategory.files.push(file.xhr.response);
    },
    mountedEditSubcategoryDropzone() {
      let mockFile = {
        accepted: true,
        cropped: true,
        kind: "image",
        ...this.editSubCategory,
      };
      let elements =
        this.$refs.editSubcategory.$el.getElementsByClassName("dz-preview");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      for (let i = 0; i < this.editSubCategory.files.length; i++) {
        mockFile.removeItemIndex = i;
        this.$refs.editSubcategory.manuallyAddFile(
          mockFile,
          this.editSubCategory.files[i]
        );
        this.$refs.editSubcategory.dropzone.emit(
          "thumbnail",
          mockFile,
          this.editSubCategory.files[i]
        );
        this.$refs.editSubcategory.dropzone.emit("complete", mockFile);
      }
    },
    closeDialogEditSubCategory() {
      this.dialogEditSubCategory = false;
      this.showSubcategoryFile = false;
      this.editSubCategoryFilePdf = [];
    },
    handleSuccessCreateSubCategory(file) {
      if (this.createdSubCategory.files.length === 0) {
        this.createdSubCategory.main_file = file.xhr.response;
      }
      this.createdSubCategory.files.push(file.xhr.response);
    },
    async customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append("lending_settings_id", this.landing.settings_id);
      formData.append("image", _file);
    },
    changeMainImage(obj, img) {
      obj.main_file = img;
    },
    async onFileChange(file, item) {
      if (file) {
        try {
          let formData = new FormData();
          formData.append("lending_settings_id", this.landing.settings_id);
          formData.append("image", file);
          formData.append("file", file);
          const response = await this.$axios.post("upload-image", formData);
          item.pdf_file = response.data;
        } catch (e) {
          this.error = e.message;
          this.$refs.PopupError.open();
        }
      }
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    closeDialogCreateSubCategory() {
      this.dialogCreateSubCategory = false;
      this.showSubcategoryFile = false;
    },
    async createNewSubCategory() {
      if (!this.selectedCategory.id) {
        this.successMessage = "Choose category for create subcategory!";
        this.$refs.PopupSuccess.open();
        return;
      }
      this.createdSubCategory.product_category_id = this.selectedCategory.id;
      const response = await this.$axios.post(
        `${process.env.VUE_APP_API_URL}product-sub-category`,
        this.createdSubCategory
      );
      this.selectedCategory.sub_categories.push(response.data);
      this.dialogCreateSubCategory = false;
      this.successMessage = "New subcategory successfull created!";
      this.$refs.PopupSuccess.open();
    },
  },
};
</script>

<style scoped></style>
