<template>
  <div>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="uploadCSV" max-width="800px">
      <v-card>
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="closeUploadCSV"
          >mdi-close</v-icon
        >
        <v-card-title>Upload csv data</v-card-title>
        <v-card-text>
          <!--          <div style="margin-bottom: 15px">-->
          <!--            <label class="label">Select group</label>-->
          <!--            <v-select-->
          <!--              flat-->
          <!--              dense-->
          <!--              outlined-->
          <!--              hide-details-->
          <!--              placeholder="Select group"-->
          <!--              :items="groups"-->
          <!--              item-text="name"-->
          <!--              item-value="index"-->
          <!--              return-object-->
          <!--              class="canvas__select"-->
          <!--              v-model="selectedGroup"-->
          <!--            >-->
          <!--            </v-select>-->
          <!--          </div>-->
          <div>
            <label class="label" style="margin: 15px 0; display: block"
              >File uploader
            </label>
          </div>
          <div>
            <v-btn
              color="primary"
              outlined
              small
              :loading="isSelecting"
              @click="handleFileImport"
            >
              Upload File
            </v-btn>
            {{ selectedFile ? selectedFile.name : "" }}
          </div>
          <v-file-input
            accept="text/csv"
            show-size
            dense
            prepend-icon=""
            ref="uploader"
            class="d-none"
            outlined
            @change="onFileChange"
          ></v-file-input>
          <div style="max-width: 500px; margin-top: 15px">
            To add display buttons on the detail page of the listing, use:
            <br />
            [button=https:/tourist.com]Tourist[/button]<br />

            To add an active link, use<br />
            [url=https://tourist.com]Tourist[/url]<br /><br />

            We have prepared for you a sample csv table, which you can
            <a href="https://tourist.hr">get here</a>
          </div>
        </v-card-text>
        <v-card-actions
          style="padding-bottom: 15px; display: flex; justify-content: flex-end"
        >
          <v-btn
            @click="openUploadImageDialog"
            color="primary"
            outlined
            dense
            small
            style="max-width: 100px; align-self: flex-end"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="uploadImage" max-width="800px">
      <v-card>
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="closeUploadImageDialog"
          >mdi-close</v-icon
        >
        <v-card-title>We accepted .jpg/.png/.gif/.svg images</v-card-title>
        <v-card-text>
          <vue-dropzone
            :id="`uploadCSV`"
            :ref="`uploadCSV`"
            :options="dropzoneOptionsUploadCSV"
            style="margin-bottom: 15px"
            maxFiles="100"
            :destroyDropzone="false"
            @vdropzone-sending="customEventUploadCSV"
            @vdropzone-success="handleSuccessUploadCSV"
          >
          </vue-dropzone>
          <!--              @vdropzone-removed-file="(file) => handleRemoveMain(file, i, listingIndex)"-->
          <label class="label">We are waiting for pictures with names:</label>
          <div
            v-for="img in uploadedImages"
            :key="img"
            style="margin-right: 15px"
          >
            {{ img }}
          </div>
        </v-card-text>
        <v-card-actions
          style="padding-bottom: 15px; display: flex; justify-content: flex-end"
        >
          <v-btn
            @click="completeUploadCSV"
            color="primary"
            outlined
            dense
            small
            style="max-width: 100px; align-self: flex-end"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import vueDropzone from "vue2-dropzone";
import { mapState } from "vuex";
import ProductsService from "../../../../services/products.service";
export default {
  name: "ProductUploadCsvDialog",
  components: {
    vueDropzone,
  },
  data() {
    return {
      uploadImage: false,
      selectedFile: "",
      isSelecting: false,
      uploadedImages: [],
      dropzoneOptionsUploadCSV: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 100,
        destroyDropzone: false,
      },
    };
  },
  props: ["menuID", "uploadCSV"],
  computed: {
    ...mapState(["landing"]),
    ...mapState("products", ["categories"]),
  },
  methods: {
    closeUploadCSV() {
        this.$emit('closeCSV')
    },
    handleFileImport() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.$refs.input.click();
    },
    async onFileChange(file) {
      this.selectedFile = file;
      this.uploadedImages = [];
      await this.$papa.parse(file, {
        complete: async (result) => {
          for (let i = 1; i < result.data.length; i++) {
            const productName = result.data[i][0];
            const productSmallDescription = result.data[i][1];
            const productFullDescription = result.data[i][2];
            const productMainImage = result.data[i][3];
            const productDetailImages = result.data[i][4]
              .split(",")
              .reduce((acc, item) => {
                acc.push(item.trim());
                return acc;
              }, []);
            const productPrice = +result.data[i][5];
            const productVideoLink = result.data[i][6];
            const productHiddenID = result.data[i][7]?+result.data[i][7]:null;
            const categoryName = result.data[i][8];
            const categoryImg = result.data[i][9];
            const categoryHiddenID = result.data[i][10]?+result.data[i][10]:null;
            const subcategoryName = result.data[i][11];
            const subcategoryImg = result.data[i][12];
            const subcategoryHiddenID = result.data[i][13]?+result.data[i][13]:null;
            const lang = result.data[i][14];
            const defaultLang = this.landing.advanced.default_lang;
            if (
              productMainImage &&
              !productMainImage.includes("guesteco.com") &&
              !this.uploadedImages.includes(productMainImage)
            ) {
              this.uploadedImages.push(productMainImage);
            }
            if (productDetailImages.length) {
              for (
                let indexImg = 0;
                indexImg < productDetailImages.length;
                indexImg++
              ) {
                if (
                  !this.uploadedImages.includes(productDetailImages[indexImg])
                ) {
                  this.uploadedImages.push(productDetailImages[indexImg]);
                }
              }
            }
            if (
              categoryImg &&
              !categoryImg.includes("guesteco.com") &&
              !this.uploadedImages.includes(categoryImg)
            ) {
              this.uploadedImages.push(categoryImg);
            }
            if (
              subcategoryImg &&
              !subcategoryImg.includes("guesteco.com") &&
              !this.uploadedImages.includes(subcategoryImg)
            ) {
              this.uploadedImages.push(subcategoryImg);
            }
            if (categoryHiddenID) {
              const categoryCandidate = this.categories.find(
                (category) => category.hidden_id === categoryHiddenID
              );
              let categoryID, subCategoryID, categoryIndex, subCategoryIndex;
              if (categoryCandidate) {
                categoryID = categoryCandidate.id;
                categoryIndex = this.categories.indexOf(categoryCandidate);
                const categoryTemplate = JSON.parse(
                  JSON.stringify(categoryCandidate)
                );
                categoryTemplate.main_file = categoryImg;
                if (lang) {
                  categoryTemplate.name[lang] = categoryName;
                } else {
                  categoryTemplate.name[defaultLang] = categoryName;
                }
                const newCategory = await ProductsService.editCategory(
                  categoryTemplate
                );
                this.categories.splice(categoryIndex, 1, newCategory);
              } else {
                const categoryTemplate = {
                  name: {},
                  files: [],
                  hotel_id: this.$route.params.id,
                  main_file: categoryImg,
                  pdf_file: "",
                  active: 1,
                  menu_id: this.menuID,
                    hidden_id:categoryHiddenID
                };
                if (lang) {
                  categoryTemplate.name[lang] = categoryName;
                } else {
                  categoryTemplate.name[defaultLang] = categoryName;
                }
                const newCategory = await ProductsService.createCategory(
                  categoryTemplate
                );
                this.categories.push(newCategory);
                categoryID = newCategory.id;
                categoryIndex = this.categories.length - 1;
              }
              if (subcategoryHiddenID) {

                const subCategoryCandidate = this.categories[
                  categoryIndex
                ].sub_categories.find(
                  (subcategory) => subcategory.hidden_id === subcategoryHiddenID
                );
                if (subCategoryCandidate) {
                  subCategoryID = subCategoryCandidate.id;
                  subCategoryIndex =
                    this.categories[categoryIndex].sub_categories.indexOf(
                      subCategoryCandidate
                    );
                  const subCategoryTemplate = JSON.parse(
                    JSON.stringify(subCategoryCandidate)
                  );
                  subCategoryTemplate.main_file = subcategoryImg;
                  if (lang) {
                    subCategoryTemplate.name[lang] = subcategoryName;
                  } else {
                    subCategoryTemplate.name[defaultLang] = subcategoryName;
                  }
                  const newSubcategory = await ProductsService.editSubCategory(
                    subCategoryTemplate
                  );
                  this.categories[categoryIndex].sub_categories.splice(
                    subCategoryIndex,
                    1,
                    newSubcategory
                  );
                } else {
                  const subCategoryTemplate = {
                    name: {},
                    files: [],
                    hotel_id: "",
                    main_file: subcategoryImg,
                    pdf_file: "",
                    active: 1,
                    product_category_id: categoryID,
                      hidden_id:subcategoryHiddenID
                  };
                  if (lang) {
                    subCategoryTemplate.name[lang] = subcategoryName;
                  } else {
                    subCategoryTemplate.name[defaultLang] = subcategoryName;
                  }
                  const newSubcategory =
                    await ProductsService.createSubCategory(
                      subCategoryTemplate
                    );
                  this.categories[categoryIndex].sub_categories.push(
                    newSubcategory
                  );
                  subCategoryID = newSubcategory.id;
                  subCategoryIndex =
                    this.categories[categoryIndex].sub_categories.length - 1;
                }
              }
              let productCandidate, productCandidateIndex;
              if (subCategoryID >= 0) {
                  if(productHiddenID){
                      productCandidate = this.categories[
                          categoryIndex
                          ].sub_categories[subCategoryIndex].products.find(
                          (product) => product.hidden_id === productHiddenID
                      );
                      if (productCandidate) {
                          productCandidateIndex =
                              this.categories[categoryIndex].sub_categories[
                                  subCategoryIndex
                                  ].products.indexOf(productCandidate);
                      }
                  }
              }
              else {
                  if(productHiddenID){
                      productCandidate = this.categories[categoryIndex].products.find(
                          (product) => product.hidden_id === productHiddenID
                      );
                      if (productCandidate) {
                          productCandidateIndex =
                              this.categories[categoryIndex].products.indexOf(
                                  productCandidate
                              );
                      }
                  }
              }
              if (productCandidate) {
                const productTemplate = JSON.parse(
                  JSON.stringify(productCandidate)
                );
                if (lang) {
                  productTemplate.name[lang] = productName;
                  productTemplate.small_description[lang] =
                    productSmallDescription;
                  productTemplate.description[lang] = productFullDescription;
                } else {
                  productTemplate.name[defaultLang] = productName;
                  productTemplate.small_description[defaultLang] =
                    productSmallDescription;
                  productTemplate.description[defaultLang] =
                    productFullDescription;
                }
                productTemplate.main_file = productMainImage;
                productTemplate.detail_files = productDetailImages;
                productTemplate.price = productPrice;
                productTemplate.video_link = productVideoLink;
                const newProduct = await ProductsService.editProduct(
                  productTemplate
                );
                if (subCategoryID >= 0) {
                  this.categories[categoryIndex].sub_categories[
                    subCategoryIndex
                  ].products.splice(productCandidateIndex, 1, newProduct);
                } else {
                  this.categories[categoryIndex].products.splice(
                    productCandidateIndex,
                    1,
                    newProduct
                  );
                }
              } else {
                const productTemplate = {
                  name: {},
                  description: {},
                  small_description: {},
                  options: [],
                  recommended: [],
                  similar: [],
                  allergens: "",
                  video: productVideoLink,
                    hidden_id:productHiddenID,
                  product_category_id: categoryID,
                  product_sub_category_id:
                    subCategoryID || subCategoryID === 0 ? subCategoryID : null,
                  main_file: productMainImage,
                  weight: 0,
                  unit: "g",
                  active: 1,
                  files: [],
                  detail_files: productDetailImages,
                  tags: [],
                  price: productPrice,
                  currency: this.landing.advanced.global.currency,
                  start_time: 0,
                  end_time: 0,
                };
                if (lang) {
                  productTemplate.name[lang] = productName;
                  productTemplate.small_description[lang] =
                    productSmallDescription;
                  productTemplate.description[lang] = productFullDescription;
                } else {
                  productTemplate.name[defaultLang] = productName;
                  productTemplate.small_description[defaultLang] =
                    productSmallDescription;
                  productTemplate.description[defaultLang] =
                    productFullDescription;
                }
                const newProduct = await ProductsService.createProduct(
                  productTemplate
                );
                if (subCategoryID) {
                  this.categories[categoryIndex].sub_categories[
                    subCategoryIndex
                  ].products.push(newProduct);
                } else {
                  this.categories[categoryIndex].products.push(newProduct);
                }
              }
            }
          }
        },
      });
    },
    openUploadImageDialog() {
      this.uploadImage = true;
      this.$emit('closeCSV')
    },
    closeUploadImageDialog() {
      this.uploadImage = false;
      this.uploadedImages = [];
    },
    customEventUploadCSV(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append(
        "lending_settings_id",
        this.$store.state.landing.settings_id
      );
      formData.append("image", _file);
    },
    normalizeImg(img) {
      if (img) {
        return img
          .replaceAll(" ", "")
          .replaceAll("&", "")
          .replaceAll("-", "")
          .toLowerCase()
          .replaceAll("jpeg", "jpg")
          .replaceAll("š", "s")
          .replaceAll("ć", "c")
          .replaceAll("š", "s")
          .replaceAll("ć", "c");
      } else {
        return "";
      }
    },
    async handleSuccessUploadCSV(file) {
      let trigger = true;
      for (let i = 0; i < this.categories.length; i++) {
        if (
          this.normalizeImg(this.categories[i].main_file) ===
          this.normalizeImg(file.name)
        ) {
          this.categories[i].main_file = file.xhr.response;
          await ProductsService.editCategory(this.categories[i]);
            this.uploadedImages.splice(this.uploadedImages.indexOf(file.name),1);
          trigger = false;
        }
        for (
          let index = 0;
          index < this.categories[i].sub_categories.length;
          index++
        ) {
          if (
            this.normalizeImg(
              this.categories[i].sub_categories[index].main_file
            ) === this.normalizeImg(file.name)
          ) {
            this.categories[i].sub_categories[index].main_file =
              file.xhr.response;
            await ProductsService.editSubCategory(
              this.categories[i].sub_categories[index]
            );
              this.uploadedImages.splice(this.uploadedImages.indexOf(file.name),1);
            trigger = false;
          }
          for (
            let productIndex = 0;
            productIndex <
            this.categories[i].sub_categories[index].products.length;
            productIndex++
          ) {
            if (
              this.normalizeImg(
                this.categories[i].sub_categories[index].products[productIndex]
                  .main_file
              ) === this.normalizeImg(file.name)
            ) {
              this.categories[i].sub_categories[index].products[
                productIndex
              ].main_file = file.xhr.response;
              await ProductsService.editProduct(
                this.categories[i].sub_categories[index].products[productIndex]
              );
                this.uploadedImages.splice(this.uploadedImages.indexOf(file.name),1);
              trigger = false;
            }
            for (
              let imgIndex = 0;
              imgIndex <
              this.categories[i].sub_categories[index].products[productIndex]
                .detail_files.length;
              imgIndex++
            ) {
              if (
                this.normalizeImg(
                  this.categories[i].sub_categories[index].products[
                    productIndex
                  ].detail_files[imgIndex]
                ) === this.normalizeImg(file.name)
              ) {
                this.categories[i].sub_categories[index].products[
                  productIndex
                ].detail_files[imgIndex] = file.xhr.response;
                await ProductsService.editProduct(
                  this.categories[i].sub_categories[index].products[
                    productIndex
                  ]
                );
                  this.uploadedImages.splice(this.uploadedImages.indexOf(file.name),1);
                trigger = false;
              }
            }
          }
        }
        for (
          let index = 0;
          index < this.categories[i].products.length;
          index++
        ) {
          if (
            this.normalizeImg(this.categories[i].products[index].main_file) ===
            this.normalizeImg(file.name)
          ) {
            this.categories[i].products[index].main_file = file.xhr.response;
            await ProductsService.editProduct(
              this.categories[i].products[index]
            );
              this.uploadedImages.splice(this.uploadedImages.indexOf(file.name),1);
            trigger = false;
          }
          for (
            let imgIndex = 0;
            imgIndex < this.categories[i].products[index].detail_files.length;
            imgIndex++
          ) {
            if (
              this.normalizeImg(
                this.categories[i].products[index].detail_files[imgIndex]
              ) === this.normalizeImg(file.name)
            ) {
              this.categories[i].products[index].detail_files[imgIndex] =
                file.xhr.response;
              await ProductsService.editProduct(
                this.categories[i].products[index]
              );
                this.uploadedImages.splice(this.uploadedImages.indexOf(file.name),1);
              trigger = false;
            }
          }
        }
      }
      if (trigger) {
        this.$refs.uploadCSV.removeFile(file);
      }
    },
    completeUploadCSV() {
      this.uploadImage = false;
    },
  },
  mounted() {
    //
  },
};
</script>

<style scoped></style>
