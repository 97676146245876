<template>
  <div>
    <div style="display: flex;align-items: center;margin-bottom: 15px;" :style="`justify-content:${styles?'space-between':'flex-end'};`">
      <div v-if="styles" style="display: flex; align-items: center;">
        <v-btn color="primary" outlined  @click="openStyles" small
        ><v-icon>mdi-chevron-left</v-icon>
          <span>Back to products list</span></v-btn
        >
      </div>
      <v-menu offset-y open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              outlined
              small
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title style="cursor: pointer"
                               @click="openStyles"
            > {{styles ?'Content settings' : 'Style settings'}}</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="cursor: pointer"
                               @click="$emit('OpenCartSettings' )"
            >Cart settings</v-list-item-title
            >
          </v-list-item>
            <v-list-item>
                <v-list-item-title style="cursor: pointer" @click="showUpload = true"
                >Upload csv</v-list-item-title
                >
            </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div
      v-if="!styles"
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      "
    >
      <div>
        <v-btn color="primary" outlined @click="$emit('openCategory')" small
          ><v-icon>mdi-chevron-left</v-icon>
          <span
            >Back to {{showName}} list</span
          ></v-btn
        >
      </div>
      <v-btn
        class="object__submit btn btn_primary"
        style="float: right"
        color="primary"
        small
        outlined
        solo
        @click="createProductForCategory"
      >
        Create product
      </v-btn>
    </div>

    <div style="margin-bottom: 15px" v-if="!styles">
      <v-data-table
        :headers="headersProduct"
        hide-default-footer
        :items="selectedCategory.products"
        class="table"
        :items-per-page="-1"
      >
        <template v-slot:body="{ items }">
          <tbody v-if="items.length">
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell" style="text-align: center">
                <img
                  :src="
                    item.main_file
                      ? item.main_file
                      : require('@/assets/none.png')
                  "
                  alt=""
                  style="width: 50px; height: 50px"
                />
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                {{ item.name[landing.current_lang] }}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                {{ item.price }} {{ item.currency }}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                <v-chip
                  class="ma-2"
                  :color="item.active ? 'green' : 'red'"
                  text-color="white"
                  style="cursor: pointer"
                  @click="changeProductStatus(item)"
                >
                  {{ item.active ? "Active" : "Inactive" }}
                </v-chip>
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                <v-menu offset-y open-on-hover bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined small>
                      <v-icon>mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      style="cursor: pointer"
                      @click="editProductShow(item)"
                    >
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        @click="openDeletePopupProduct(item)"
                        style="cursor: pointer; color: red"
                        >Delete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
          <tbody v-if="items.length === 0">
            <tr>
              <td class="d-block d-sm-table-cell"
                colspan="6"
                style="text-align: center; padding: 0 15px; font-weight: normal"
              >
                You don't have products yet, it's time to create them
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>

    <div v-if="styles">
      <ProductMenuProductStyles :content="content" />
    </div>
    <!--Create new product dialog-->
    <v-dialog :fullscreen="$store.state.isMobile"
      v-model="dialogCreateProduct"
      max-width="710"
      @click:outside="createProductHide"
    >
      <v-card style="min-height: 700px">
        <v-toolbar style="width: 100%">
          <div>Create product</div>
          <v-icon
            small
            depressed
            outlined
            style="position: absolute; right: 20px; top: 20px; font-size: 20px"
            @click="createProductHide"
            size="20px"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <div>
          <v-tabs>
            <v-tab> General </v-tab>
            <v-tab>More</v-tab>
            <v-tab>Extra</v-tab>
            <v-tab>Options</v-tab>
            <v-tab-item style="padding-top: 15px">
              <div style="padding: 15px">
                <div style="margin-bottom: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Choose your product image list
                  </div>
                  <vue-dropzone
                    style="margin-bottom: 15px"
                    id="createProductDropzone"
                    :options="dropzoneOptions"
                    :destroyDropzone="false"
                    ref="createProductDropzone"
                    @vdropzone-sending="customEvent"
                    @vdropzone-success="handleSuccessCreateProduct"
                  >
                  </vue-dropzone>
                </div>
                <div style="margin-bottom: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Choose your product image detail
                  </div>
                  <vue-dropzone
                    style="margin-bottom: 15px"
                    id="createProductDropzoneDetail"
                    :options="dropzoneOptions"
                    :destroyDropzone="false"
                    ref="createProductDropzoneDetail"
                    @vdropzone-sending="customEvent"
                    @vdropzone-success="handleSuccessCreateProductDetail"
                  >
                  </vue-dropzone>
                </div>
                <v-tabs  v-model="activeLang">
                  <v-tab
                    v-for="(lang, i) in landing.selected_langs"
                    :key="i"
                    @change="changeLang(lang)"
                  >
                    <img
                      :src="
                        landing.advanced.langs.find((x) => x.value === lang)
                          .flag
                      "
                      alt=""
                      style="width: 20px; height: 15px; margin-right: 5px"
                    />
                    {{ lang }}
                  </v-tab>

                  <v-tab-item
                    style="padding-top: 15px"
                    v-for="(lang, i) in landing.selected_langs"
                    :key="i"
                  >
                    <div class="label" style="margin-bottom: 15px">
                      Product name
                    </div>
                    <v-text-field
                      style="margin-bottom: 15px"
                      outlined
                      dense
                      hide-details
                      v-model="createdProduct.name[lang]"
                      placeholder="Your product name"
                    ></v-text-field>
                    <div class="label" style="margin: 15px 0">
                      Product small description
                    </div>
                    <Editor
                      api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                      :init="options"
                      v-model="createdProduct.small_description[lang]"
                    >
                    </Editor>
                    <div class="label" style="margin: 15px 0">
                      Product description
                    </div>
                    <Editor
                      api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                      :init="options"
                      v-model="createdProduct.description[lang]"
                    >
                    </Editor>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-tab-item>
            <v-tab-item style="padding-top: 15px">
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">Price</div>
                  <v-currency-field
                    style="margin-bottom: 15px"
                    outlined
                    :decimal-length="2"
                    dense
                    type="number"
                    hide-details
                    v-model="createdProduct.price"
                    placeholder="Your product price"
                  />
                </div>
                <div style="flex: 1; padding-left: 15px">
                  <div class="label" style="margin-bottom: 15px">Currency</div>
                  <div class="control">
                    <v-autocomplete
                      solo
                      flat
                      outlined
                      dense
                      style="z-index: 204 !important;"
                      placeholder="Select currency"
                      :items="currency"
                      item-value="value"
                      item-text="name"
                      hide-details
                      v-model="createdProduct.currency"
                    >
                      <template v-slot:selection="data">
                        {{ data.item.name }}
                      </template>
                      <template v-slot:item="data">
                        {{ data.item.name }}
                      </template>
                    </v-autocomplete>
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">Weight</div>
                  <v-currency-field
                    style="margin-bottom: 15px"
                    outlined
                    :decimal-length="2"
                    dense
                    type="number"
                    hide-details
                    v-model="createdProduct.weight"
                    placeholder="Your product weight"
                  />
                </div>
                <div style="flex: 1; padding-left: 15px">
                  <div class="label" style="margin-bottom: 15px">Units</div>
                  <div class="control">
                    <v-select
                      solo
                      flat
                      outlined
                      dense
                      placeholder="Select unit"
                      :items="units"
                      item-value="value"
                      item-text="name"
                      hide-details
                      style="z-index:204 !important;"
                      v-model="createdProduct.unit"
                    >
                      <template v-slot:selection="{item}">
                        {{ item.name }}
                      </template>
                      <template v-slot:item="{item}">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Start time
                  </div>
                  <v-menu
                    ref="createdProductStartTime"
                    v-model="createdProductStartTime"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="createdProduct.start_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="createdProduct.start_time"
                        outlined
                        dense
                        hide-details
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="createdProductStartTime"
                      v-model="createdProduct.start_time"
                      full-width
                      format="24hr"
                      @click:minute="
                        $refs.createdProductStartTime.save(
                          createdProduct.start_time
                        )
                      "
                    ></v-time-picker>
                  </v-menu>
                </div>
                <div style="flex: 1; padding-left: 15px">
                  <div class="label" style="margin-bottom: 15px">End time</div>
                  <v-menu
                    ref="createdProductEndTime"
                    v-model="createdProductEndTime"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="createdProduct.end_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="createdProduct.end_time"
                        outlined
                        dense
                        hide-details
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="createdProductEndTime"
                      v-model="createdProduct.end_time"
                      full-width
                      format="24hr"
                      @click:minute="
                        $refs.createdProductEndTime.save(
                          createdProduct.end_time
                        )
                      "
                    ></v-time-picker>
                  </v-menu>
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Video link
                  </div>
                  <v-text-field
                    style="margin-bottom: 15px"
                    outlined
                    dense
                    hide-details
                    v-model="createdProduct.video"
                    placeholder="Your video link"
                  />
                </div>
                <div style="flex: 1; padding-left: 15px">
                  <div class="label" style="margin-bottom: 15px">Allergens</div>
                  <v-text-field
                    style="margin-bottom: 15px"
                    outlined
                    dense
                    hide-details
                    v-model="createdProduct.allergens"
                    placeholder="Product allergens"
                  />
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
              <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Hidden ID
                  </div>
                  <v-text-field
                      style="margin-bottom: 15px"
                      outlined
                      dense
                      hide-details
                      v-model="createdProduct.hidden_id"
                      placeholder="Your hidden id"
                  />
                </div>
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Meal Serving Time
                  </div>
                  <v-text-field
                      style="margin-bottom: 15px"
                      outlined
                      dense
                      type="number"
                      hide-details
                      v-model.number="createdProduct.meal_serving_time"
                      placeholder="Your meal serving time"
                  />
                </div>
              </div>
            </v-tab-item>
            <v-tab-item style="padding-top: 15px">
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Recommended
                  </div>
                  <v-combobox
                    v-model="createdProduct.recommended"
                    item-text="name"
                    class="canvas__select"
                    item-value="id"
                    :items="allProducts"
                    placeholder="Select product"
                    multiple
                    style="z-index: 204 !important;"
                    :return-object="false"
                    outlined
                    dense
                    hide-details
                    flat
                  >
                    <template v-slot:selection="{ item }">
                      {{ showProductName(item) }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{ item.name[landing.current_lang] }}
                    </template>
                  </v-combobox>
                </div>
                <div style="flex: 1; padding-left: 15px">
                  <div style="flex: 1; padding-right: 15px">
                    <div class="label" style="margin-bottom: 15px">Similar</div>
                    <v-combobox
                      v-model="createdProduct.similar"
                      item-text="name"
                      class="canvas__select"
                      item-value="id"
                      :items="allProducts"
                      placeholder="Select product"
                      multiple
                      hide-details
                      outlined
                      style="z-index: 204 !important;"
                      :return-object="false"
                      dense
                      flat
                    >
                      <template v-slot:selection="{ item }">
                        {{ showProductName(item) }}
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item.name[landing.current_lang] }}
                      </template>
                    </v-combobox>
                  </div>
                </div>
              </div>
              <div style="padding: 15px">
                <div class="label" style="margin-bottom: 15px">Tags</div>
                <div style="display: flex; flex-wrap: wrap">
                  <div
                    style="
                      width: 50%;
                      padding-right: 15px;
                      margin-bottom: 10px;
                      display: flex;
                      align-items: center;
                    "
                    v-for="tag in tags"
                    :key="tag.id"
                  >
                    <v-checkbox
                      v-model="tag.value"
                      hide-details
                      style="margin: 0"
                      @change="createdTagChange(tag)"
                    >
                    </v-checkbox>
                    <div
                      style="
                        position: relative;
                        bottom: -2px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <img
                        :src="`/tags_product/${tag.img}`"
                        style="width: 20px; height: 20px; margin-right: 10px"
                        :alt="tag.name"
                      />
                      <div class="label">{{ tag.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item style="padding-top: 15px">
              <div style="padding: 15px">
                <div class="label" style="margin-bottom: 15px">Options</div>

                <v-tabs  v-model="activeLang">
                  <v-tab
                    v-for="(lang, i) in landing.selected_langs"
                    :key="i"
                    @change="changeLang(lang)"
                  >
                    <img
                      :src="
                        landing.advanced.langs.find((x) => x.value === lang)
                          .flag
                      "
                      alt=""
                      style="width: 20px; height: 15px; margin-right: 5px"
                    />
                    {{ lang }}
                  </v-tab>

                  <v-tab-item
                    style="padding-top: 15px"
                    v-for="(lang, i) in landing.selected_langs"
                    :key="i"
                  >
                    <div
                      v-for="(option, optionIndex) in createdProduct.options"
                      :key="option.id"
                      style="display: flex"
                    >
                      <div style="flex: 1; padding-right: 15px">
                        <div
                          class="label"
                          style="margin-bottom: 15px"
                          v-if="!optionIndex"
                        >
                          Name
                        </div>
                        <v-text-field
                          style="margin-bottom: 15px"
                          outlined
                          dense
                          hide-details
                          v-model="option.name[lang]"
                          placeholder="Name"
                        />
                      </div>
                      <div style="flex: 1; padding: 0 15px">
                        <div
                          class="label"
                          style="margin-bottom: 15px"
                          v-if="!optionIndex"
                        >
                          Price
                        </div>
                        <v-currency-field
                          outlined
                          :decimal-length="2"
                          dense
                          type="number"
                          hide-details
                          v-model="option.price"
                          placeholder="Price"
                        />
                      </div>
                      <div style="flex: 1; padding-left: 15px">
                        <div
                          class="label"
                          style="margin-bottom: 15px"
                          v-if="!optionIndex"
                        >
                          Status
                        </div>
                        <v-chip
                          class="ma-2"
                          :color="option.active ? 'green' : 'red'"
                          text-color="white"
                          style="cursor: pointer"
                          @click="changeOptionStatus(option)"
                        >
                          {{ option.active ? "Active" : "Inactive" }}
                        </v-chip>
                      </div>
                    </div>
                  </v-tab-item>
                </v-tabs>
                <v-btn
                  small
                  outlined
                  dense
                  color="primary"
                  @click="createdProductAddOption"
                >
                  + add option
                </v-btn>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
        <v-card-actions class="justify-end">
          <v-btn
            outlined
            small
            @click="createProductConfirm"
            style="margin-bottom: 20px; max-width: 360px"
            color="primary"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Create new product dialog-->

    <!--Edit product dialog-->
    <v-dialog :fullscreen="$store.state.isMobile"
      v-model="dialogEditedProduct"
      max-width="710"
      @click:outside="editProductHide"
    >
      <v-card style="min-height: 700px">
        <v-toolbar style="width: 100%">
          <div>Edit product</div>
          <v-icon
            small
            depressed
            outlined
            style="position: absolute; right: 20px; top: 20px; font-size: 20px"
            @click="editProductHide"
            size="20px"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <div>
          <v-tabs>
            <v-tab> General </v-tab>
            <v-tab>More</v-tab>
            <v-tab>Extra</v-tab>
            <v-tab>Options</v-tab>
            <v-tab-item style="padding-top: 15px">
              <div style="padding: 15px">
                <div style="margin-bottom: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Choose your product image
                  </div>
                  <vue-dropzone
                    style="margin-bottom: 15px"
                    id="editProductDropzone"
                    :options="dropzoneOptions"
                    :destroyDropzone="false"
                    ref="editProductDropzone"
                    @vdropzone-sending="customEvent"
                    @vdropzone-success="handleSuccessEditedProduct"
                    @vdropzone-mounted="editProductDropzoneMount"
                  >
                  </vue-dropzone>
                </div>
                <div style="margin-bottom: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Choose your product detail image
                  </div>
                  <vue-dropzone
                    style="margin-bottom: 15px"
                    id="editProductDropzoneDetail"
                    :options="dropzoneOptions"
                    :destroyDropzone="false"
                    ref="editProductDropzoneDetail"
                    @vdropzone-sending="customEvent"
                    @vdropzone-success="handleSuccessEditedProductDetail"
                    @vdropzone-mounted="editProductDropzoneMountDetail"
                  >
                  </vue-dropzone>
                </div>
                <v-tabs  v-model="activeLang">
                  <v-tab
                    v-for="(lang, i) in landing.selected_langs"
                    :key="i"
                    @change="changeLang(lang)"
                  >
                    <img
                      :src="
                        landing.advanced.langs.find((x) => x.value === lang).flag
                      "
                      alt=""
                      style="width: 20px; height: 15px; margin-right: 5px"
                    />
                    {{ lang }}
                  </v-tab>

                  <v-tab-item
                    style="padding-top: 15px"
                    v-for="(lang, i) in landing.selected_langs"
                    :key="i"
                  >
                    <div class="label" style="margin-bottom: 15px">
                      Product name
                    </div>
                    <v-text-field
                      style="margin-bottom: 15px"
                      outlined
                      dense
                      hide-details
                      v-model="editedProduct.name[lang]"
                      placeholder="Your product name"
                    ></v-text-field>
                    <div class="label" style="margin: 15px 0">
                      Product description
                    </div>
                    <Editor
                      api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                      :init="options"
                      v-model="editedProduct.description[lang]"
                    >
                    </Editor>
                    <div class="label" style="margin: 15px 0">
                      Product small description
                    </div>
                    <Editor
                      api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                      :init="options"
                      v-model="editedProduct.small_description[lang]"
                    >
                    </Editor>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-tab-item>
            <v-tab-item style="padding-top: 15px">
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">Price</div>
                  <v-currency-field
                    style="margin-bottom: 15px"
                    outlined
                    :decimal-length="2"
                    dense
                    type="number"
                    hide-details
                    v-model="editedProduct.price"
                    placeholder="Your product price"
                  />
                </div>
                <div style="flex: 1; padding-left: 15px">
                  <div class="label" style="margin-bottom: 15px">Currency</div>
                  <div class="control">
                    <v-autocomplete
                      solo
                      flat
                      outlined
                      dense
                      style="z-index: 204 !important;"
                      placeholder="Select currency"
                      :items="currency"
                      item-value="value"
                      item-text="name"
                      hide-details
                      v-model="editedProduct.currency"
                    >
                      <template v-slot:selection="data">
                        {{ data.item.name }}
                      </template>
                      <template v-slot:item="data">
                        {{ data.item.name }}
                      </template>
                    </v-autocomplete>
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">Weight</div>
                  <v-currency-field
                    style="margin-bottom: 15px"
                    outlined
                    :decimal-length="2"
                    dense
                    type="number"
                    hide-details
                    v-model="editedProduct.weight"
                    placeholder="Your product weight"
                  />
                </div>
                <div style="flex: 1; padding-left: 15px">
                  <div class="label" style="margin-bottom: 15px">Units</div>
                  <div class="control">
                    <v-autocomplete
                      solo
                      flat
                      outlined
                      dense
                      placeholder="Select unit"
                      :items="units"
                      item-value="value"
                      item-text="name"
                      style="z-index:204 !important;"
                      hide-details
                      v-model="editedProduct.unit"
                    >
                      <template v-slot:selection="{item}">
                        {{ item.name }}
                      </template>
                      <template v-slot:item="{item}">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Start time
                  </div>
                  <v-menu
                    ref="editedProductStartTime"
                    v-model="editedProductStartTime"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editedProduct.start_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedProduct.start_time"
                        outlined
                        dense
                        hide-details
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="editedProductStartTime"
                      v-model="editedProduct.start_time"
                      full-width
                      format="24hr"
                      @click:minute="
                        $refs.editedProductStartTime.save(
                          editedProduct.start_time
                        )
                      "
                    ></v-time-picker>
                  </v-menu>
                </div>
                <div style="flex: 1; padding-left: 15px">
                  <div class="label" style="margin-bottom: 15px">End time</div>
                  <v-menu
                    ref="editedProductEndTime"
                    v-model="editedProductEndTime"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="editedProduct.end_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedProduct.end_time"
                        outlined
                        dense
                        hide-details
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="editedProductEndTime"
                      v-model="editedProduct.end_time"
                      full-width
                      format="24hr"
                      @click:minute="
                        $refs.editedProductEndTime.save(editedProduct.end_time)
                      "
                    ></v-time-picker>
                  </v-menu>
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Video link
                  </div>
                  <v-text-field
                    style="margin-bottom: 15px"
                    outlined
                    dense
                    hide-details
                    v-model="editedProduct.video"
                    placeholder="Video link"
                  />
                </div>
                <div style="flex: 1; padding-left: 15px">
                  <div class="label" style="margin-bottom: 15px">Allergens</div>
                  <v-text-field
                    style="margin-bottom: 15px"
                    outlined
                    dense
                    hide-details
                    v-model="editedProduct.allergens"
                    placeholder="Product allergens"
                  />
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Hidden ID
                  </div>
                  <v-text-field
                      style="margin-bottom: 15px"
                      outlined
                      dense
                      hide-details
                      v-model="editedProduct.hidden_id"
                      placeholder="Your hidden id"
                  />
                </div>
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Meal Serving Time
                  </div>
                  <v-text-field
                      style="margin-bottom: 15px"
                      outlined
                      dense
                      hide-details
                      type="number"
                      v-model.number="editedProduct.meal_serving_time"
                      placeholder="Your meal serving time"
                  />
                </div>
              </div>
            </v-tab-item>
            <v-tab-item style="padding-top: 15px">
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Recommended
                  </div>
                  <v-combobox
                    v-model="editedProduct.recommended"
                    item-text="name"
                    class="canvas__select"
                    item-value="id"
                    :items="allProducts"
                    placeholder="Select product"
                    multiple
                    return-object
                    style="z-index: 204 !important;"
                    outlined
                    dense
                    hide-details
                    flat
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.name[landing.current_lang] }}<br>
                    </template>
                    <template v-slot:item="{ item }">
                      {{ item.name[landing.current_lang] }}
                    </template>
                  </v-combobox>
                </div>
                <div style="flex: 1; padding-left: 15px">
                  <div style="flex: 1; padding-right: 15px">
                    <div class="label" style="margin-bottom: 15px">Similar</div>
                    <v-combobox
                      v-model="editedProduct.similar"
                      item-text="name"
                      class="canvas__select"
                      item-value="id"
                      :items="allProducts"
                      placeholder="Select product"
                      multiple
                      hide-details
                      outlined
                      style="z-index: 204 !important;"
                      return-object
                      dense
                      flat
                    >
                      <template v-slot:selection="{ item }">
                        {{ item.name[landing.current_lang] }} <br>
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item.name[landing.current_lang] }}
                      </template>
                    </v-combobox>
                  </div>
                </div>
              </div>
              <div style="padding: 15px">
                <div class="label" style="margin-bottom: 15px">Tags</div>
                <div style="display: flex; flex-wrap: wrap">
                  <div
                    style="
                      width: 50%;
                      padding-right: 15px;
                      margin-bottom: 10px;
                      display: flex;
                      align-items: center;
                    "
                    v-for="tag in tags"
                    :key="tag.id"
                  >
                    <v-checkbox
                      v-model="tag.value"
                      @change="editedTagChange(tag)"
                      hide-details
                      style="margin: 0"
                    >
                    </v-checkbox>
                    <div
                      style="
                        position: relative;
                        bottom: -2px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <img
                        :src="`/tags_product/${tag.img}`"
                        style="width: 20px; height: 20px; margin-right: 10px"
                        :alt="tag.name"
                      />
                      <div class="label">{{ tag.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item style="padding-top: 15px">
              <div style="padding: 15px">
                <div class="label" style="margin-bottom: 15px">Options</div>
                <v-tabs  v-model="activeLang">
                  <v-tab
                    v-for="(lang, i) in landing.selected_langs"
                    :key="i"
                    @change="changeLang(lang)"
                  >
                    <img
                      :src="
                        landing.advanced.langs.find((x) => x.value === lang)
                          .flag
                      "
                      alt=""
                      style="width: 20px; height: 15px; margin-right: 5px"
                    />
                    {{ lang }}
                  </v-tab>

                  <v-tab-item
                    style="padding-top: 15px"
                    v-for="(lang, i) in landing.selected_langs"
                    :key="i"
                  >
                    <div
                      v-for="(option, optionIndex) in editedProduct.options"
                      :key="option.id"
                      style="display: flex"
                    >
                      <div style="flex: 1; padding-right: 15px">
                        <div
                          class="label"
                          style="margin-bottom: 15px"
                          v-if="!optionIndex"
                        >
                          Name
                        </div>
                        <v-text-field
                          style="margin-bottom: 15px"
                          outlined
                          dense
                          hide-details
                          v-model="option.name[lang]"
                          placeholder="Your name"
                        />
                      </div>
                      <div style="flex: 1; padding: 0 15px">
                        <div
                          class="label"
                          style="margin-bottom: 15px"
                          v-if="!optionIndex"
                        >
                          Price
                        </div>
                        <v-currency-field
                          outlined
                          :decimal-length="2"
                          dense
                          type="number"
                          hide-details
                          v-model="option.price"
                          placeholder="Your product price"
                        />
                      </div>
                      <div style="flex: 1; padding-left: 15px">
                        <div
                          class="label"
                          style="margin-bottom: 15px"
                          v-if="!optionIndex"
                        >
                          Status
                        </div>
                        <v-chip
                          class="ma-2"
                          :color="option.active ? 'green' : 'red'"
                          text-color="white"
                          style="cursor: pointer"
                          @click="changeOptionStatus(option)"
                        >
                          {{ option.active ? "Active" : "Inactive" }}
                        </v-chip>
                      </div>
                    </div>
                  </v-tab-item>
                </v-tabs>
                <v-btn
                  small
                  outlined
                  dense
                  color="primary"
                  @click="editedProductAddOption"
                >
                  + add option
                </v-btn>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
        <v-card-actions class="justify-end">
          <v-btn
            outlined
            small
            @click="editProductConfirm"
            style="margin-bottom: 20px; max-width: 360px"
            color="primary"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Edit product dialog-->
    <PopupSuccess ref="PopupSuccess" :title="'Thank you!'" :content="successMessage" />
    <PopupDelete
      ref="PopupDeleteProduct"
      @confirm="deleteProduct"
      title="Are your sure about delete product?"
    />
    <PopupError :title="error" ref="PopupError" />
  </div>
</template>

<script>
import vueDropzone from "vue2-dropzone";
import { mapState } from "vuex";
import ProductMenuProductStyles from "./ProductMenuProductStyles.vue";
import PopupSuccess from "@/components/Popup/PopupSuccess.vue";
import PopupDelete from "@/components/Popup/PopupDelete.vue";
import PopupError from "@/components/Popup/PopupError.vue";
import axios from 'axios'
import ProductsService from "../../../../services/products.service";
import Compressor from "compressorjs";
export default {
  name: "ProductMenuProduct",
  components: {
    vueDropzone,
    PopupSuccess,
    PopupDelete,
    PopupError,
    ProductMenuProductStyles,
  },
  props: ["selectedCategory", "content","item","selectedMenuID"],
  computed: {
    ...mapState(["landing"]),
    ...mapState("products", ["categories"]),
    showName(){
      let arr = this.$store.state.products.pageProduct.split("/");
      if (arr.length === 3 && arr[0] === 'productList') {
        return 'subcateogies'
      } else {
        return 'categories'
      }
    },
    allProducts() {
      return this.categories.reduce((acc, item) => {
        if (item.products.length) {
          acc = [...acc, ...item.products];
        }
        if (item.sub_categories.length) {
          for (let i = 0; i < item.sub_categories.length; i++) {
            acc = [...acc, ...item.sub_categories[i].products];
          }
        }
        return acc;
      }, []);
    },
    activeLang: {
      set: function (val) {},
      get: function () {
        return this.landing.advanced.selected_langs.indexOf(
          this.landing.current_lang
        );
      },
    },
  },
  data() {
    return {
      showUpload:false,
      successMessage: "",
      headersProduct: [
        { text: "Image", value: "main_file", align: "center" },
        { text: "Name", value: "name", align: "center", sortable: true },
        { text: "Price", value: "price", align: "center", sortable: true },
        { text: "Status", value: "active", align: "center", sortable: true },
        { text: "Options", align: "center", sortable: true },
      ],
      error: "",
      styles: false,
      createdProduct: {
        name: { en: "" },
        description: { en: "" },
        small_description: { en: "" },
        options: [],
        recommended: [],
        similar: [],
        allergens: "",
        video: "",
        product_category_id: null,
        product_sub_category_id: null,
        main_file: "",
        weight: 0,
        meal_serving_time:0,
        unit: "g",
        active: 1,
        files: [],
        detail_files: [],
        tags: [],
        price: 0,
        currency: "€",
        start_time: "",
        end_time: "",
        hidden_id:"",
        order:0
      },
      defaultProduct:{
        name: { en: "" },
        description: { en: "" },
        small_description: { en: "" },
        options: [],
        recommended: [],
        similar: [],
        allergens: "",
        video: "",
        product_category_id: null,
        product_sub_category_id: null,
        main_file: "",
        weight: 0,
        unit: "g",
        active: 1,
        meal_serving_time:0,
        files: [],
        detail_files: [],
        tags: [],
        price: 0,
        currency: "",
        start_time: "",
        end_time: "",
        hidden_id:"",
        order:0
      },
      editedProduct: {
        name: { en: "" },
        meal_serving_time:0,
        description: { en: "" },
        small_description: { en: "" },
        options: [],
        recommended: [],
        similar: [],
        allergens: "",
        video: "",
        product_category_id: null,
        product_sub_category_id: null,
        main_file: "",
        weight: 0,
        unit: "g",
        active: 1,
        files: [],
        detail_files: [],
        tags: [],
        price: 0,
        currency: "",
        start_time: 0,
        end_time: 0,
        hidden_id:"",
        order:0
      },
      dialogCreateProduct: false,
      dialogEditedProduct: false,
      createdProductStartTime: false,
      createdProductEndTime: false,
      editedProductStartTime: false,
      editedProductEndTime: false,
      tags: [
        {
          id: 0,
          value: false,
          name: "The dish is made from frozen products",
          img: "Icons-01.png",
        },
        {
          id: 1,
          value: false,
          name: "Gluten-free product",
          img: "Icons-02.png",
        },
        {
          id: 2,
          value: false,
          name: "Contains frozen ingredients",
          img: "Icons-03.png",
        },
        {
          id: 3,
          value: false,
          name: "Vegetarian ingredients",
          img: "Icons-05.png",
        },
        {
          id: 4,
          value: false,
          name: "Contains mushrooms",
          img: "Icons-06.png",
        },
        {
          id: 5,
          value: false,
          name: "Lactose-free ingredients",
          img: "Icons-07.png",
        },
        { id: 6, value: false, name: "Vegan ingredients", img: "Icons-08.png" },
        { id: 7, value: false, name: "Contains meat", img: "Icons-09.png" },
        {
          id: 8,
          value: false,
          name: "The dish contains seafood",
          img: "Icons-10.png",
        },
        { id: 9, value: false, name: "Chefs choice", img: "Icons-11.png" },
        { id: 10, value: false, name: "Drink of the day", img: "Icons-12.png" },
        { id: 11, value: false, name: "Dish of the day", img: "Icons-13.png" },
        { id: 12, value: false, name: "Dessert of the day", img: "Icons-14.png" },
        { id: 13, value: false, name: "Snack of the day", img: "Icons-15.png" },
        { id: 14, value: false, name: "Hit", img: "Icons-16.png" },
      ],
      currency: [
        { id: 0, value: "$", name: "Dollar $" },
        { id: 1, value: "€", name: "Euro €" },
        { id: 2, value: "Kn", name: "Kuna Kn" },
      ],
      units: [
        { id: 0, value: "g", name: "gram (g)" },
        { id: 1, value: "kg", name: "kilogram (kg)" },
        { id: 2, value: "mg", name: "milligram (mg)" },
        { id: 3, value: "l", name: "liter (l)" },
        { id: 4, value: "ml", name: "milliliter (ml)" },
      ],
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: "jpg,svg,webp,gif,png",
        images_upload_handler: async (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), {
            lastModified: new Date().getTime(),
            type: blobInfo.blob().type,
          });
          formData.append(
            "lending_settings_id",
            this.$store.state.landing.settings_id
          );
          formData.append("image", _file);
          formData.append("file", _file);
          const { data } = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}upload-image`,
            formData
          );
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: "jpg,svg,webp,gif,png",
          images_upload_handler: async (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), {
              lastModified: new Date().getTime(),
              type: blobInfo.blob().type,
            });
            formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
            );
            formData.append("image", _file);
            formData.append("file", _file);
            const { data } = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}upload-image`,
              formData
            );
            success(data);
          },
          plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 250,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

          setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
      },
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        maxFiles: 3,
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        transformFile:function(file,done){
          if(file.compress){
            done(file);
          }else{
            new Compressor(file, {
              quality: 0.6,
              mimeType:'image/webp',
              convertSize:0,
              convertTypes:['image/jpeg','image/png','image/jpg'],
              success:(result) => {
                this.removeFile(file);
                result.compress = true;
                this.addFile(result);
                done(result)
              },
              error(e) {
                console.log(e);
              }
            })
          }
        }
      },
      createdProductShow: false,
    };
  },
  methods: {
    categoryName(id, val) {
      if (val === "subcategory") {
        const candidateCategory = this.categories.find(
          (x) => x.id === id.product_category_id
        );
        if (candidateCategory) {
          const candidate = this.categories.find(
            (x) => x.id === id.product_sub_category_id
          );
          if (candidate) {
            return candidate.name[this.landing.current_lang];
          } else {
            return "";
          }
        } else {
          return "";
        }
      } else {
        const candidate = this.categories.find(
          (x) => x.id === id.product_category_id
        );
        if (candidate) {
          return candidate.name[this.landing.current_lang];
        } else {
          return "";
        }
      }
    },
    handleSuccessCreateProductDetail(file) {
      this.createdProduct.detail_files.push(file.xhr.response);
    },
    openDeletePopupProduct(item) {
      this.deleteProductItem = item;
      this.$refs.PopupDeleteProduct.open();
    },
    async changeProductStatus(item) {
      try {
        if (item.active) {
          item.active = 0;
        } else {
          item.active = 1;
        }
        await this.$axios.put(`/product/${item.id}`, item);
      } catch (e) {
        this.error = e.message;
        this.$refs.PopupError.open();
      }
    },
    async createProductForCategory(item) {
      if(this.$refs.createProductDropzone){
        let elements =
            this.$refs.createProductDropzone.$el.getElementsByClassName(
                "dz-preview"
            );
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      }
      if(this.$refs.createProductDropzoneDetail){
        let elements =
            this.$refs.createProductDropzoneDetail.$el.getElementsByClassName(
                "dz-preview"
            );
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      }
      this.createdProduct = JSON.parse(JSON.stringify(this.defaultProduct))
      this.dialogCreateProduct = true;
      this.tags.forEach((item) => {
        const candidate = this.createdProduct.tags.find(
          (x) => x.id === item.id
        );
        if (candidate) {
          item.value = true;
        }
      });
    },
    async deleteProduct() {
      try {
        await this.$axios.delete(`/product/${this.deleteProductItem.id}`);
        this.selectedCategory.products.splice(
          this.selectedCategory.products.indexOf(this.deleteProductItem),
          1
        );
        this.successMessage = `Product succesfully deleted!`;
        this.$refs.PopupSuccess.open();
      } catch (e) {
        this.successMessage = e.message;
        this.$refs.PopupSuccess.open();
      }
    },
    openStyles() {
      this.styles = !this.styles;
    },
    async createProductConfirm() {
      try {
        const obj = JSON.parse(JSON.stringify(this.createdProduct))
        if (this.selectedCategory.sub_categories) {
          obj.product_category_id = this.selectedCategory.id;
        } else {
          obj.product_category_id =
            this.selectedCategory.product_category_id;
          obj.product_sub_category_id =
            this.selectedCategory.id;
        }
        if (obj.start_time) {
          obj.start_time = this.changeTimeToMs(
              obj.start_time
          );
        } else {
          obj.start_time = 0;
        }
        if (obj.end_time) {
          obj.end_time = this.changeTimeToMs(
              obj.end_time
          );
        } else {
          obj.end_time = 0;
        }
        const response = await this.$axios.post(
          `/product`,
            obj
        );
        this.successMessage = "Your product created successful!";
        this.dialogCreateProduct = false;
        this.$refs.PopupSuccess.open();
        this.selectedCategory.products.push(response.data);
        this.tags.forEach((item) => {
          item.value = false;
        });
      } catch (e) {
        this.error = e.message;
        this.$refs.PopupError.open();
      }
    },
    changeTimeToMs(time) {
      let ms = 0;
      let arr = time.split(":");
      ms += +arr[0] * 3600000;
      ms += +arr[1] * 60000;
      return ms;
    },
    changeMsToTime(ms) {
      let hours = (ms / 3600000).toFixed(0);
      if (hours.length === 1) {
        hours = `0${hours}`;
      }
      let minutes = (ms % 60000).toFixed(0);
      if (minutes.length === 1) {
        if (+minutes < 10) {
          minutes = `${minutes}0`;
        } else {
          minutes = `0${minutes}`;
        }
      }
      return `${hours}:${minutes}`;
    },
    async editProductConfirm() {
      try {
        const product = JSON.parse(JSON.stringify(this.editedProduct));
        if (product.start_time) {
          product.start_time = this.changeTimeToMs(
              product.start_time
          );
        }
        if (product.end_time) {
          product.end_time = this.changeTimeToMs(
              product.end_time
          );
        }
        const response = await this.$axios.put(
          `product/${product.id}`,
            product
        );
        const candidate = this.selectedCategory.products.find(
          (x) => x.id === product.id
        );
        if (candidate) {
          this.selectedCategory.products.splice(
            this.selectedCategory.products.indexOf(candidate),
            1,
            response.data
          );
        }
        this.successMessage = "Your product edited succesful!";
        this.dialogEditedProduct = false;
        this.tags.forEach((item) => {
          item.value = false;
        });
        this.$refs.PopupSuccess.open();
      } catch (e) {
        this.error = e.message;
        this.$refs.PopupError.open();
      }
    },
    createdProductAddOption() {
      const option = {
        id: this.createdProduct.options.length,
        name: { en: "" },
        price: 0,
        active: 1,
        value: 0,
      };
      this.landing.advanced.selected_langs.forEach((lang) => {
        if (!option.name[lang]) {
          option.name[lang] = "";
        }
      });
      this.createdProduct.options.push(option);
    },
    handleSuccessEditedProduct(file) {
      if (this.editedProduct.files.length === 0) {
        this.editedProduct.main_file = file.xhr.response;
      }
      this.editedProduct.files.push(file.xhr.response);
    },
    handleSuccessEditedProductDetail(file) {
      this.editedProduct.detail_files.push(file.xhr.response);
    },
    changeOptionStatus(option) {
      if (option.active) {
        option.active = 0;
      } else {
        option.active = 1;
      }
    },
    createdTagChange(tag) {
      if (tag.value) {
        this.createdProduct.tags.push(tag);
      } else {
        const candidate = this.createdProduct.tags.find((x) => x.id === tag.id);
        if (candidate) {
          this.createdProduct.tags.splice(
            this.createdProduct.tags.indexOf(candidate),
            1
          );
        }
      }
    },
    editedTagChange(tag) {
      if (tag.value) {
        this.editedProduct.tags.push(tag);
      } else {
        const candidate = this.editedProduct.tags.find((x) => x.id === tag.id);
        if (candidate) {
          this.editedProduct.tags.splice(
            this.editedProduct.tags.indexOf(candidate),
            1
          );
        }
      }
    },
    showProductName(id) {
      const candidate = this.allProducts.find((x) => x.id === id);
      if (candidate) {
        return candidate.name[this.landing.current_lang];
      } else {
        return "";
      }
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    editedProductAddOption() {
      const option = {
        id: this.editedProduct.options.length,
        name: { en: "" },
        price: 0,
        active: 1,
        value: 0,
      };
      this.landing.advanced.selected_langs.forEach((lang) => {
        if (!option.name[lang]) {
          option.name[lang] = "";
        }
      });
      this.editedProduct.options.push(option);
    },
    changeMainImage(obj, img) {
      obj.main_file = img;
    },
    editProductShow(item) {
      this.editedProduct = JSON.parse(JSON.stringify(item));
      this.tags.forEach((item) => {
        const candidate = this.editedProduct.tags.find((x) => x.id === item.id);
        if (candidate) {
          item.value = true;
        }
      });
      if (this.editedProduct.start_time) {
        this.editedProduct.start_time = this.changeMsToTime(
            this.editedProduct.start_time
        );
      }
      if (this.editedProduct.end_time) {
        this.editedProduct.end_time = this.changeMsToTime(
            this.editedProduct.end_time
        );
      }
      if(this.$refs.editProductDropzone){
        this.editProductDropzoneMount();
      }
      if(this.$refs.editProductDropzoneDetail){
        this.editProductDropzoneMountDetail();
      }
      this.dialogEditedProduct = true;
    },
    editProductDropzoneMount() {
      let mockFile = {
        accepted: true,
        cropped: true,
        kind: "image",
        ...this.editedProduct,
      };
      let elements =
        this.$refs.editProductDropzone.$el.getElementsByClassName("dz-preview");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      if(this.editedProduct.main_file){

        this.$refs.editProductDropzone.manuallyAddFile(
          mockFile,
            this.editedProduct.main_file
        );
        this.$refs.editProductDropzone.dropzone.emit(
          "thumbnail",
          mockFile,
            this.editedProduct.main_file
        );
        this.$refs.editProductDropzone.dropzone.emit("complete", mockFile);
      }
    },
    editProductDropzoneMountDetail() {
      if(this.editedProduct.detail_files.length){
        let mockFile = {
          accepted: true,
          cropped: true,
          kind: "image",
          ...this.editedProduct,
        };
        let elements =
            this.$refs.editProductDropzoneDetail.$el.getElementsByClassName(
                "dz-preview"
            );
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
        for (let i = 0; i < this.editedProduct.detail_files.length; i++) {
          if(this.editedProduct.detail_files[i]){
            mockFile.removeItemIndex = i;
            this.$refs.editProductDropzoneDetail.manuallyAddFile(
                mockFile,
                this.editedProduct.detail_files[i]
            );
            this.$refs.editProductDropzoneDetail.dropzone.emit(
                "thumbnail",
                mockFile,
                this.editedProduct.detail_files[i]
            );
            this.$refs.editProductDropzoneDetail.dropzone.emit(
                "complete",
                mockFile
            );
          }
        }
      }else{
        let elements =
            this.$refs.editProductDropzoneDetail.$el.getElementsByClassName(
                "dz-preview"
            );
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },
    createProductHide() {
      this.dialogCreateProduct = false;
      this.tags.forEach((item) => {
        item.value = false;
      });
      this.createdProduct = JSON.parse(JSON.stringify(this.defaultProduct));
    },
    editProductHide() {
      this.dialogEditedProduct = false;
      this.tags.forEach((item) => {
        item.value = false;
      });
    },
    async customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append("lending_settings_id", this.landing.settings_id);
      formData.append("image", _file);
    },
    handleSuccessCreateProduct(file) {
      if (this.createdProduct.files.length === 0) {
        this.createdProduct.main_file = file.xhr.response;
      }
      this.createdProduct.files.push(file.xhr.response);
    },
  },
};
</script>

<style scoped></style>
