<template>
  <div>
    <div style="margin-bottom: 15px;">
      <div class="control">
        <label class="label" style="font-weight: bold;font-size:20px;">Block settings</label>
      </div>
      <div style="padding:0 15px;">
        <div style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="width:200px;margin-bottom: 0;">
            Width <b>{{ content.styles.subcategory.width }}%</b>
          </label>
          <div class="slider" style="width:80%;">
            <v-slider
                hide-details
                :max="100"
                :min="33"
                v-model="content.styles.subcategory.width"
            ></v-slider>
          </div>
        </div>
        <div style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="width:200px;margin-bottom: 0;">
            Height <b>{{ content.styles.subcategory.height }}px</b>
          </label>
          <div class="slider" style="width:80%;">
            <v-slider
                hide-details
                :max="600"
                :min="30"
                v-model="content.styles.subcategory.height"
            ></v-slider>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-bottom: 15px;">
      <div class="control">
        <label class="label" style="font-weight: bold;font-size:20px;">General Settings</label>
      </div>
      <div style="padding:0 15px;">
        <div style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="width:200px;margin-bottom: 0;">Name position</label>
          <v-select :items="['bottom','inside']" v-model="content.styles.subcategory.show_name" outlined dense hide-details
                    style="max-width:250px;"></v-select>
        </div>
        <div style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="width:200px;margin-bottom: 0;">
            Font Color
          </label>
          <div class="control">
            <v-menu
                top
                nudge-left="16"
                nudge-bottom="98"
                :close-on-content-click="false"
                v-model="show_text_color"
            >
              <template v-slot:activator="{ on }">
                <div
                    class="custom__menu--color"
                    :style="`background-color:${content.styles.subcategory.color}`"
                    v-on="on"
                />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker
                      v-model="content.styles.subcategory.color"
                      flat
                  />
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </div>
        <div style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="width:200px;margin-bottom: 0;">
            Font Size <b>{{ content.styles.subcategory.fontSize }}px</b>
          </label>
          <div class="slider" style="width:80%;">
            <v-slider
                hide-details
                :max="40"
                :min="10"
                v-model="content.styles.subcategory.fontSize"
            ></v-slider>
          </div>
        </div>
        <div style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="width:200px;margin-bottom: 0;">
            Font Weight <b>{{ parseInt(content.styles.subcategory.fontWeight, 10) / 10 }}%</b>
          </label>
          <div class="slider" style="width:80%;">
            <v-slider
                hide-details
                :max="1000"
                :min="100"
                v-model="content.styles.subcategory.fontWeight"
                :step="100"
            ></v-slider>
          </div>
        </div>
        <div style="display: flex;justify-content: space-between;margin-bottom:15px;">
          <div style="display: flex;align-items: center;margin-bottom: 15px;">
            <label class="label" style="width:200px;margin-bottom: 0;">
              Font Style <b>{{ content.styles.subcategory.fontStyle }}</b>
            </label>
            <div class="control">
              <v-radio-group hide-details row v-model="content.styles.subcategory.fontStyle">
                <v-radio
                    v-for="(type,typeIndex) in fontStyleTypes"
                    :key="type.id"
                    :label="type.label"
                    :value="type.value"
                    :style="`margin-left:${typeIndex?'15px':0}`"
                >
                </v-radio>
              </v-radio-group>
            </div>
          </div>
          <div style="display: flex;align-items: center;margin-bottom: 15px;">
            <label class="label" style="width:200px;margin-bottom: 0;">
              Change Font Family
            </label>

            <v-select
                flat
                dense
                outlined
                hide-details
                placeholder="Select Font Family"
                :items="fontFamilyTypes"
                item-text="label"
                item-value="value"
                class="canvas__select"
                v-model="content.styles.subcategory.fontFamily"
                style="max-width:250px;"
            >
              <template v-slot:selection="{ item }">
                <div :style="`font-family:'${item.value}',sans-serif`">
                  {{ item.label }}
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div :style="`font-family:'${item.value}',sans-serif`">
                  {{ item.label }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="control">
        <label class="label" style="font-weight: bold;font-size:20px;">Image Settings</label>
      </div>
      <div style="padding:0 15px;">
        <div style="display: flex;align-items: center;margin-bottom: 15px;">
          <label class="label" style="width:200px;margin-bottom: 0;">Show Image</label>
          <v-switch color="#6ab64a" v-model="content.styles.subcategory.show_icon" hide-details>
          </v-switch>
        </div>
        <div v-if="content.styles.subcategory.show_icon">

          <div style="display: flex;align-items: center;margin-bottom: 15px;">
            <label class="label" style="width:200px;margin-bottom: 0;">Height <b>{{ content.styles.subcategory.img_height }}</b> px</label>
            <div class="slider" style="width: 80%;">
              <v-slider
                  hide-details
                  :min="10"
                  :max="600"
                  v-model="content.styles.subcategory.img_height"
              ></v-slider>
            </div>
          </div>

          <div style="display: flex;align-items: center;margin-bottom: 15px;">
            <label class="label" style="width:200px;margin-bottom: 0;">
              Width <b>{{ content.styles.subcategory.img_width }}</b> %</label>
            <div class="slider" style="width: 80%;">
              <v-slider
                  hide-details
                  :min="10"
                  :max="100"
                  v-model="content.styles.subcategory.img_width"
              ></v-slider>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "ProductMenuStyles",
    props:['content'],
    data(){
      return {
        show_text_color: false,
        show_text_price_color: false,
        btnColorCart:false,
        btnBorderCart:false,
        btnBgCart:false,
        btnColorMore:false,
        btnBorderMore:false,
        btnBgMore:false,
        fontStyleTypes: [
          { id: 1, value: "normal", label: "Normal" },
          { id: 0, value: "italic", label: "Italic" },
        ],
        fontFamilyTypes: [
          { id: 0, value: "Arial", label: "Arial" },
          { id: 1, value: "SF UI Text", label: "SF UI Text" },
          { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
          { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
          { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
          { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
          { id: 6, value: "Geometria", label: "Geometria" },
          { id: 7, value: "DIN Pro", label: "DIN Pro" },
          { id: 8, value: "Circe", label: "Circe" },
          { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
          { id: 10, value: "Acrom", label: "Acrom" },
          { id: 11, value: "Inter", label: "Inter" },
        ],
        positionTypes: [
          { id: 0, valueHorizontal: "flex-start" },
          { id: 1, valueHorizontal: "center" },
          { id: 2, valueHorizontal: "flex-end" },
        ],
      }
    },
    methods:{
      changeLang(lang) {
        this.$store.commit("landing/setCurrentLang", lang);
      },
    },
    mounted() {
      // console.log(this.content)
    },
    computed:{
      ...mapState(['landing']),
      activeLang:{
        set:function(val){

        },
        get:function(){
          return this.landing.advanced.selected_langs.indexOf(this.landing.current_lang)
        }
      },
    }
  }
</script>

<style scoped lang="scss">

</style>
