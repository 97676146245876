<template>
  <div>
    <div
      style="
        display: flex;
        align-items: center;
        margin-bottom: 15px;
      "
      :style="`justify-content:${styles?'space-between':'flex-end'};`"
    >
      <div v-if="styles">
        <v-btn color="primary" outlined @click="openStyles" small
        ><v-icon>mdi-chevron-left</v-icon>
          <span
          >Back to category</span
          ></v-btn
        >
      </div>
      <v-menu offset-y open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              outlined
              small
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title style="cursor: pointer" @click="openStyles">
              {{
                styles ? "Content settings" : "Style settings"
              }}</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              style="cursor: pointer"
              @click="$emit('OpenCartSettings')"
              >Cart settings</v-list-item-title
            >
          </v-list-item>
<!--          <v-list-item>-->
<!--            <v-list-item-title style="cursor: pointer" @click="openUploadCSV"-->
<!--              >Upload CSV categories</v-list-item-title-->
<!--            >-->
<!--          </v-list-item>-->
<!--          <v-list-item>-->
<!--            <v-list-item-title-->
<!--              style="cursor: pointer"-->
<!--              @click="showUpload = true"-->
<!--              >Upload CSV products</v-list-item-title-->
<!--            >-->
<!--          </v-list-item>-->
<!--          <v-list-item>-->
<!--            <v-list-item-title-->
<!--              style="cursor: pointer"-->
<!--              @click="downloadCSV"-->
<!--              >Download CSV products</v-list-item-title-->
<!--            >-->
<!--          </v-list-item>-->
        </v-list>
      </v-menu>
    </div>
    <div
      v-if="!styles"
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      "
    >
      <label class="label" style="margin-bottom: 0">Categories list</label>
      <v-btn
        class="object__submit btn btn_primary"
        style="float: right"
        color="primary"
        outlined
        small
        solo
        @click="createProductCategory"
      >
        Create category
      </v-btn>
    </div>

    <div style="margin-bottom: 15px" v-if="!styles">
      <v-data-table
        class="table"
        :headers="headers"
        :items="categories"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:body="{ items }">
          <tbody v-if="items.length">
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell" style="text-align: center">{{ item.id }}</td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                {{ item.name[landing.current_lang] }}
              </td>
              <td class="d-block d-sm-table-cell"
                style="text-align: center; cursor: pointer"
                v-if="item.sub_categories.length"
                @click="$emit('showSubcategoriesList', item)"
              >
                {{ item.sub_categories.length }}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center" v-else>
                {{ item.sub_categories.length }}
              </td>
              <td class="d-block d-sm-table-cell"
                style="text-align: center; cursor: pointer"
                v-if="item.products.length"
                @click="$emit('openDialogProductsList', item)"
              >
                {{ item.products.length }}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center" v-else>
                {{ item.products.length }}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                <v-chip
                  class="ma-2"
                  :color="item.active ? 'green' : 'red'"
                  text-color="white"
                  style="cursor: pointer"
                  @click="changeCategoryStatus(item)"
                >
                  {{ item.active ? "Active" : "Inactive" }}
                </v-chip>
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                <v-menu offset-y open-on-hover bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined small>
                      <v-icon>mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      style="cursor: pointer"
                      @click="openEditCategory(item)"
                    >
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        style="cursor: pointer; color: red"
                        @click="openDeletePopup(item)"
                        >Delete</v-list-item-title
                      >
                    </v-list-item>

                    <v-list-item v-if="!item.pdf_file && !item.products.length">
                      <v-list-item-title
                        style="cursor: pointer"
                        @click="$emit('showSubcategoriesList', item)"
                      >
                        List subcategories
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="!item.pdf_file && !item.sub_categories.length"
                    >
                      <v-list-item-title
                        style="cursor: pointer"
                        @click="$emit('openDialogProductsList', item)"
                      >
                        List products
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
          <tbody v-if="items.length === 0">
            <tr>
              <td class="d-block d-sm-table-cell"
                colspan="8"
                style="text-align: center; padding: 0 15px; font-weight: normal"
              >
                You don't have categories yet, it's time to create them
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>

    <div v-if="styles">
      <ProductMenuStyles :content="content" />
    </div>

    <!--Create new category dialog-->
    <v-dialog :fullscreen="$store.state.isMobile" v-model="dialogCreateCategory" max-width="750">
      <v-card>
        <v-card-title
          ><span>Create category</span>
          <v-icon
            style="position: absolute; top: 10px; right: 10px"
            @click="closeDialogCreate"
            >mdi-close</v-icon
          ></v-card-title
        >
        <div style="padding: 30px">
          <v-tabs v-model="activeLang" style="margin-bottom: 30px">
            <v-tab
              v-for="(lang, i) in landing.selected_langs"
              :key="i"
              @change="changeLang(lang)"
            >
              <img
                :src="landing.advanced.langs.find((x) => x.value === lang).flag"
                alt=""
                style="width: 20px; height: 15px; margin-right: 5px"
              />
              {{ lang }}
            </v-tab>
            <v-tab-item
              style="padding-top: 15px"
              v-for="(lang, i) in landing.selected_langs"
              :key="i"
            >
              <div class="label" style="margin-bottom: 15px">Category name</div>
              <v-text-field
                outlined
                dense
                hide-details
                style="max-width: 50%"
                v-model="createdCategory.name[lang]"
                placeholder="Your category name"
              ></v-text-field>
            </v-tab-item>
          </v-tabs>
            <div style="margin-bottom: 15px;">
                <div class="label" style="margin-bottom: 15px">Category hidden id</div>
                <v-text-field
                        outlined
                        dense
                        hide-details
                        style="max-width: 50%"
                        v-model="createdCategory.hidden_id"
                        placeholder="Your category hidden id"
                ></v-text-field>
            </div>

          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-evenly;
            "
          >
            <div style="flex: 1; margin-bottom: 15px">
              <div class="label" style="margin-bottom: 10px">
                Choose your image
              </div>
              <vue-dropzone
                id="createDropzone"
                :options="dropzoneOptions"
                :destroyDropzone="false"
                ref="createDropzone"
                @vdropzone-sending="customEvent"
                @vdropzone-success="handleSuccessCreateCategory"
                @vdropzone-removed-file="handleRemove"
              >
              </vue-dropzone>
            </div>
            <div
              style="
                flex: 1;
                text-align: center;
                font-weight: bold;
                font-size: 22px;
                margin-bottom: 15px;
              "
            >
              or
            </div>
            <div class="control" style="flex: 1;position:relative;">
              <div  @click="showIcons = !showIcons">

              <label class="label">Choose your main as icon</label>
              <v-select
                  style="z-index: 21;width:220px"
                  outlined
                  solo
                  flat
                  dense
                  :menu-props="{ contentClass: 'my-awesome-list' }"
                  :items="icons.data.map((item) => item.url)"
                  :value="createdCategory.main_file"
                  v-model="createdCategory.main_file"

              >
                <template v-slot:selection="{ item }">
                  <img class="icon" :src="item" />
                </template>
                <template v-slot:item="{ item }">
                  <img class="icon" :src="item" />
                </template>
              </v-select>
              </div>
              <div v-if="showIcons" style="width:220px;height:150px;background:#fff;border:1px solid #ccc;position: absolute;top:74px;z-index: 100;display:flex;flex-wrap: wrap;overflow-y:scroll;overflow-x:hidden;">
                <div v-for="icon in icons.data.map((item) => item.url)" :key="icon"  style="height: 40px;width: 40px;padding:5px;" :style="`${createdCategory.main_file === icon?'border:2px solid #007db5;background:#ddd;':'' };`">
                  <img :src="icon" :alt="icon" style="height: 100%;width: 100%;cursor: pointer;" @click="handleChange(icon)">
                </div>
              </div>
            </div>
          </div>

          <div style="display: flex; align-items: center; margin-bottom: 15px">
            <label class="label" style="margin-right: 45px"
              >Upload pdf to category</label
            >
            <v-switch color="#6ab64a" v-model="categoryCreateFile" hide-details> </v-switch>
          </div>
          <div style="margin-bottom: 15px" v-if="categoryCreateFile">
            <div class="label" style="margin-bottom: 10px">
              Choose your category file
            </div>
            <v-file-input
              accept="application/pdf"
              outlined
              dense
              style="max-width: 50%"
              ref="pdfUpload"
              prepend-inner-icon="mdi-file"
              prepend-icon=""
              @change="(file) => onFileChange(file, createdCategory)"
            ></v-file-input>
          </div>
        </div>

        <v-card-actions class="justify-end">
          <v-btn
            small
            outlined
            style="margin-bottom: 10px; max-width: 360px"
            color="primary"
            @click="createNewCategory"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Create new category dialog-->

    <!--Edit category dialog-->
    <v-dialog :fullscreen="$store.state.isMobile"
      v-model="dialogEditCategory"
      max-width="750"
      @click:outside="closeEditCategory"
    >
      <v-card>
        <v-card-title
          ><span>Edit category</span>
          <v-icon
            style="position: absolute; top: 10px; right: 10px"
            @click="closeEditCategory"
            >mdi-close</v-icon
          ></v-card-title
        >
        <div style="padding: 30px; overflow: auto">
          <v-tabs v-model="activeLang" style="margin-bottom: 30px">
            <v-tab
              v-for="(lang, i) in landing.selected_langs"
              :key="i"
              @change="changeLang(lang)"
            >
              <img
                :src="landing.advanced.langs.find((x) => x.value === lang).flag"
                alt=""
                style="width: 20px; height: 15px; margin-right: 5px"
              />
              {{ lang }}
            </v-tab>
            <v-tab-item
              style="padding-top: 15px"
              v-for="(lang, i) in landing.selected_langs"
              :key="i"
            >
              <div class="label" style="margin-bottom: 15px">Category name</div>
              <v-text-field
                outlined
                dense
                hide-details
                style="max-width: 50%"
                v-model="editCategory.name[lang]"
                placeholder="Your category name"
              ></v-text-field>
            </v-tab-item>
          </v-tabs>
            <div style="margin-bottom: 15px;">
                <div class="label" style="margin-bottom: 15px">Category hidden id</div>
                <v-text-field
                        outlined
                        dense
                        hide-details
                        style="max-width: 50%"
                        v-model="editCategory.hidden_id"
                        placeholder="Your category hidden id"
                ></v-text-field>
            </div>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-evenly;
            "
          >
            <div style="margin-bottom: 15px; flex: 1">
              <div class="label" style="margin-bottom: 15px">
                Choose your image
              </div>
              <vue-dropzone
                id="editDropzone"
                :options="dropzoneOptions"
                :destroyDropzone="false"
                ref="editDropzone"
                @vdropzone-mounted="editDropzoneMount"
                @vdropzone-sending="customEvent"
                @vdropzone-success="handleSuccessEditCategory"
                @vdropzone-removed-file="handleRemoveEdit"
              >
              </vue-dropzone>
            </div>

            <div
              style="
                flex: 1;
                font-weight: bold;
                font-size: 22px;
                margin-bottom: 15px;
                text-align: center;
              "
            >
              or
            </div>
            <div class="control" style="flex: 1;position:relative;">
              <div  @click="showIconsEdit = !showIconsEdit">
              <label class="label">Choose your main as icon</label>
              <v-select
                  style="z-index: 21;width:220px"
                  outlined
                  solo
                  flat
                  dense
                  :menu-props="{ contentClass: 'my-awesome-list' }"
                  :items="icons.data.map((item) => item.url)"
                  :value="editCategory.main_file"
                  v-model="editCategory.main_file"
              >
                <template v-slot:selection="{ item }">
                  <img class="icon" :src="item" />
                </template>
                <template v-slot:item="{ item }">
                  <img class="icon" :src="item" />
                </template>
              </v-select>
              </div>
              <div v-if="showIconsEdit" style="width:220px;height:150px;background:#fff;border:1px solid #ccc;position: absolute;top:74px;z-index: 100;display:flex;flex-wrap: wrap;overflow-y:scroll;overflow-x:hidden;">
                <div v-for="icon in icons.data.map((item) => item.url)" :key="icon"  style="height: 40px;width: 40px;padding:5px;" :style="`${editCategory.main_file === icon?'border:2px solid #007db5;background:#ddd;':'' };`">
                  <img :src="icon" :alt="icon" style="height: 100%;width: 100%;cursor: pointer;" @click="handleChangeEdit(icon)">
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-bottom: 15px">
            <label class="label" style="margin-right: 45px"
              >Upload pdf to category</label
            >
            <v-switch color="#6ab64a" v-model="categoryEditFile" hide-details> </v-switch>
          </div>
          <div style="margin-bottom: 15px" v-if="categoryEditFile">
            <div class="label" style="margin-bottom: 10px">
              Choose your category file
            </div>
            <v-file-input
              accept="application/pdf"
              outlined
              dense
              ref="pdfUpload"
              prepend-inner-icon="mdi-file"
              style="max-width: 50%"
              prepend-icon=""
              v-model="editCategoryFilePdf"
              @change="(file) => onFileChange(file, editCategory)"
            ></v-file-input>
          </div>
        </div>
        <v-card-actions class="justify-end">
          <v-btn
            small
            outlined
            style=" margin-bottom: 10px; max-width: 360px"
            color="primary"
            @click="saveEditCategory"
            >Edit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Edit category dialog-->

    <PopupSuccess ref="PopupSuccess" :title="'Thank you!'" :content="successMessage" />
    <PopupError :title="error" ref="PopupError" />
    <PopupDelete
      ref="PopupDeleteCategory"
      title="Are your sure about delete category?"
      @confirm="deleteCategory"
    />

    <v-dialog :fullscreen="$store.state.isMobile" v-model="uploadCSV" max-width="800px">
      <v-card>
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="closeUploadCSV"
          >mdi-close</v-icon
        >
        <v-card-title>Upload csv data</v-card-title>
        <v-card-text>
          <div>
            <label class="label" style="margin: 15px 0; display: block"
              >File uploader
            </label>
          </div>
          <div>
            <v-btn
              color="primary"
              outlined
              small
              :loading="isSelecting"
              @click="handleFileImport"
            >
              Upload File
            </v-btn>
            {{ selectedFile ? selectedFile.name : "" }}
          </div>
          <v-file-input
            accept="text/csv"
            show-size
            dense
            prepend-icon=""
            ref="uploader"
            class="d-none"
            outlined
            @change="onFileChangeCSV"
          ></v-file-input>
          <div style="max-width: 500px; margin-top: 15px">
            To add display buttons on the detail page of the listing, use:
            <br />
            [button=https:/tourist.com]Tourist[/button]<br />

            To add an active link, use<br />
            [url=https://tourist.com]Tourist[/url]<br /><br />

            We have prepared for you a sample csv table, which you can
            <a href="https://tourist.hr">get here</a>
          </div>
        </v-card-text>
        <v-card-actions
          style="padding-bottom: 15px; display: flex; justify-content: flex-end"
        >
          <v-btn
            @click="openUploadImageDialog"
            color="primary"
            outlined
            dense
            small
            style="max-width: 100px; align-self: flex-end"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :fullscreen="$store.state.isMobile" v-model="uploadImage" max-width="800px">
      <v-card>
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="closeUploadImageDialog"
          >mdi-close</v-icon
        >
        <v-card-title>Upload images</v-card-title>
        <v-card-text>
          <label class="label" style="display: block; margin-bottom: 15px"
            >We accepted .jpg .png .gif .svg images type files</label
          ><br />
          <label class="label" style="display: block; margin-bottom: 15px"
            >We found in your file items of category: {{ listingCount }}</label
          >
          <vue-dropzone
            :id="`uploadCSV`"
            :ref="`uploadCSV`"
            :options="dropzoneOptionsUploadCSV"
            style="margin-bottom: 15px"
            maxFiles="100"
            :destroyDropzone="false"
            @vdropzone-sending="customEventUploadCSV"
            @vdropzone-success="handleSuccessUploadCSV"
          >
          </vue-dropzone>
          <label class="label" style="display: block; margin-bottom: 15px"
            >We uploaded {{ countOfImages - uploadedImages.length }} of
            {{ countOfImages }} images.</label
          >
          <label class="label" style="display: block; margin-bottom: 15px"
            >We are waiting for pictures with names:</label
          >
          <div
            v-for="img in uploadedImages"
            :key="img + 'uploaded'"
            style="margin-right: 15px"
          >
            {{ img }}
          </div>
        </v-card-text>
        <v-card-actions
          style="padding-bottom: 15px; display: flex; justify-content: flex-end"
        >
          <v-btn
            @click="completeUploadCSV"
            color="primary"
            outlined
            dense
            small
            style="max-width: 100px; align-self: flex-end"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ProductUploadCsvDialog
      :menuID="item.id"
      :uploadCSV="showUpload"
      @closeCSV="closeCSV"
    />

  </div>
</template>

<script>
import ProductUploadCsvDialog from "./ProductUploadCsvDialog.vue";
import vueDropzone from "vue2-dropzone";
import { mapState } from "vuex";
import ProductMenuStyles from "./ProductMenuCategoryStyles";
import moment from "moment";
import PopupSuccess from "@/components/Popup/PopupSuccess.vue";
import PopupError from "@/components/Popup/PopupError.vue";
import PopupDelete from "@/components/Popup/PopupDelete.vue";
import ImagesService from "@/services/images.service.js";
export default {
  name: "ProductMenuCategory",
  components: {
    vueDropzone,
    ProductMenuStyles,
    PopupSuccess,
    PopupError,
    PopupDelete,
    ProductUploadCsvDialog,
  },
  props: ["content", "item","selectedMenuID"],
  computed: {
    ...mapState("landing", ["icons"]),
    ...mapState(["landing"]),
    ...mapState("products", ["categories"]),
    activeLang: {
      set: function (val) {},
      get: function () {
        return this.landing.advanced.selected_langs.indexOf(
          this.landing.current_lang
        );
      },
    },
  },
  data() {
    return {
      showIcons:false,
      showIconsEdit:false,
      showUpload: false,
      countOfImages: 0,
      uploadedImages: [],
      uploadImage: false,
      isSelecting: false,
      uploadCSV: false,
      selectedFile: null,
      uploadedData: [],
      dropzoneOptionsUploadCSV: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 300,
        destroyDropzone: false,
      },
      successMessage: "",
      categoryEditFile: false,
      categoryCreateFile: false,
      error: "",
      headers: [
        { text: "ID", value: "id", align: "center", sortable: true },
        { text: "Name", value: "name", align: "center", sortable: true },
        {
          text: "Subcategories count",
          value: "sub_categories",
          align: "center",
          sortable: true,
        },
        {
          text: "Product count",
          value: "products",
          align: "center",
          sortable: true,
        },
        {
          text: "Status",
          value: "active",
          align: "center",
          sortable: true,
        },
        { text: "Options", align: "center", sortable: true },
      ],
      styles: false,
      editCategory: {
        name: { en: "" },
        files: [],
        hotel_id: "",
        main_file: "",
        pdf_file: "",
          hidden_id:"",
        active: 1,
        menu_id: "",
        order:0
      },
      dialogCreateCategory: false,
      dialogEditCategory: false,
      editCategoryFilePdf: [],
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        maxfiles: 3,
        thumbnailMethod: "contain",
        acceptedfiles: "image/*",
        addRemoveLinks: true,
      },
      createdCategory: {
        name: { en: "" },
        files: [],
        hotel_id: "",
        main_file: "https://api.guesteco.com/storage/default-landing-icons/27.svg",
          hidden_id:"",
        pdf_file: "",
        active: 1,
        menu_id: "",
        order:0
      },
      defaultCategory: {
        name: { en: "" },
        files: [],
        hotel_id: "",
        main_file: "https://api.guesteco.com/storage/default-landing-icons/27.svg",
        hidden_id:"",
        pdf_file: "",
        active: 1,
        menu_id: "",
        order:0
      },
      listingCount: 0,
    };
  },
  methods: {
    handleChange(value) {
      this.showIcons = false;
      this.createdCategory.main_file = value;
    },
    handleChangeEdit(value) {
      this.showIconsEdit = false;
      this.editCategory.main_file = value;
    },
      downloadCSV(){
          const data = [];
          for(let i = 0;i<this.categories.length;i++){
              for(let indexSub = 0;indexSub<this.categories[i].sub_categories.length;indexSub++){
                  for(let index = 0;index<this.categories[i].sub_categories[indexSub].products.length;index++){
                      for(let lang = 0;lang<this.landing.advanced.selected_langs.length;lang++){
                          let row = {
                              product_name:this.categories[i].sub_categories[indexSub].products[index].name[this.landing.advanced.selected_langs[lang]]?this.categories[i].sub_categories[indexSub].products[index].name[this.landing.advanced.selected_langs[lang]]:"",
                              product_small_description:this.categories[i].sub_categories[indexSub].products[index].small_description[this.landing.advanced.selected_langs[lang]]?this.categories[i].sub_categories[indexSub].products[index].small_description[this.landing.advanced.selected_langs[lang]]:"",
                              product_full_descirption:this.categories[i].sub_categories[indexSub].products[index].description[this.landing.advanced.selected_langs[lang]]?this.categories[i].sub_categories[indexSub].products[index].description[this.landing.advanced.selected_langs[lang]]:"",
                              product_main_image:this.categories[i].sub_categories[indexSub].products[index].main_file?this.categories[i].sub_categories[indexSub].products[index].main_file:"",
                              product_detail_images:this.categories[i].sub_categories[indexSub].products[index].detail_files.length?this.categories[i].sub_categories[indexSub].products[index].detail_files.join(', '):'',
                              product_price:this.categories[i].sub_categories[indexSub].products[index].price?this.categories[i].sub_categories[indexSub].products[index].price:"",
                              product_video_link:this.categories[i].sub_categories[indexSub].products[index].video_link?this.categories[i].sub_categories[indexSub].products[index].video_link:"",
                              product_hidden_id:this.categories[i].sub_categories[indexSub].products[index].hidden_id?this.categories[i].sub_categories[indexSub].products[index].hidden_id:"",
                              category_name:this.categories[i].name[this.landing.advanced.selected_langs[lang]]?this.categories[i].name[this.landing.advanced.selected_langs[lang]]:"",
                              category_img:this.categories[i].main_file?this.categories[i].main_file:"",
                              category_hidden_id:this.categories[i].hidden_id?this.categories[i].hidden_id:"",
                              sub_category_name:this.categories[i].sub_categories[indexSub].name[this.landing.advanced.selected_langs[lang]]?this.categories[i].sub_categories[indexSub].name[this.landing.advanced.selected_langs[lang]]:"",
                              sub_category_img:this.categories[i].sub_categories[indexSub].main_file?this.categories[i].sub_categories[indexSub].main_file:"",
                              sub_category_hidden_id:this.categories[i].sub_categories[indexSub].hidden_id?this.categories[i].sub_categories[indexSub].hidden_id:"",
                              lang:this.landing.advanced.selected_langs[lang]
                          };
                          data.push(row);
                      }
                  }
              }
              for(let index = 0;index<this.categories[i].products.length;index++){
                for(let lang = 0;lang<this.landing.advanced.selected_langs.length;lang++){
                    let row = {
                        product_name:this.categories[i].products[index].name[this.landing.advanced.selected_langs[lang]]?this.categories[i].products[index].name[this.landing.advanced.selected_langs[lang]]:"",
                        product_small_description:this.categories[i].products[index].small_description[this.landing.advanced.selected_langs[lang]]?this.categories[i].products[index].small_description[this.landing.advanced.selected_langs[lang]]:"",
                        product_full_descirption:this.categories[i].products[index].description[this.landing.advanced.selected_langs[lang]]?this.categories[i].products[index].description[this.landing.advanced.selected_langs[lang]]:"",
                        product_main_image:this.categories[i].products[index].main_file?this.categories[i].products[index].main_file:"",
                        product_detail_images:this.categories[i].products[index].detail_files.length?this.categories[i].products[index].detail_files.join(', '):'',
                        product_price:this.categories[i].products[index].price?this.categories[i].products[index].price:"",
                        product_video_link:this.categories[i].products[index].video_link?this.categories[i].products[index].video_link:"",
                        product_hidden_id:this.categories[i].products[index].hidden_id?this.categories[i].products[index].hidden_id:"",
                        category_name:this.categories[i].name[this.landing.advanced.selected_langs[lang]]?this.categories[i].name[this.landing.advanced.selected_langs[lang]]:"",
                        category_img:this.categories[i].main_file?this.categories[i].main_file:"",
                        category_hidden_id:this.categories[i].hidden_id?this.categories[i].hidden_id:"",
                        sub_category_name:"",
                        sub_category_img:"",
                        sub_category_hidden_id:"",
                        lang:this.landing.advanced.selected_langs[lang]
                    };
                    data.push(row);
                }
              }
          }
          let csv = this.$papa.unparse(data);
          let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          const link = document.createElement("a");
          link.download =
              this.item.text[this.landing.current_lang].replaceAll(" ", "_") +
              "_" +
              this.$moment().format("DD_MM_yyyy_HH_mm") +
              ".csv";
          link.href = window.URL.createObjectURL(csvData);
          link.dataset.downloadurl = ["text/csv", link.download, link.href].join(
              ":"
          );
          const evt = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
          });
          link.dispatchEvent(evt);
          link.remove();
      },
    closeCSV() {
      this.showUpload = false;
    },
    normalizeImg(img) {
      if (img) {
        return img
          .replaceAll(" ", "")
          .replaceAll("&", "")
          .replaceAll("-", "")
          .toLowerCase()
          .replaceAll("jpeg", "jpg")
          .replaceAll("š", "s")
          .replaceAll("ć", "c")
          .replaceAll("š", "s")
          .replaceAll("ć", "c");
      } else {
        return "";
      }
    },
    async onFileChangeCSV(file) {
      this.selectedFile = file;
      this.uploadedImages = [];
      this.$papa.parse(file, {
        complete: async (result) => {
          for (let i = 1; i < result.data.length; i++) {
            const img = result.data[i][1];
            const candidate = this.uploadedData.find(
              (x) => result.data[i][2] && x.hidden_id === result.data[i][2]
            );
            if (candidate) {
              candidate.name[result.data[i][3]] = result.data[i][0];
            } else {
                let id;
                if(this.item.type === 8){
                    id = +(this.selectedMenuID+""+this.item.id+'000');
                }else{
                    id = this.item.id;
                }
              const category = {
                name: {},
                files: [],
                hotel_id: this.$route.params.id,
                main_file: img,
                pdf_file: "",
                active: 1,
                menu_id: id,
                hidden_id: null,
              };
              if (result.data[i][2]) {
                category.hidden_id = result.data[i][2];
              }
              category.name[result.data[i][3]] = result.data[i][0];
              this.uploadedData.push(category);
            }
            if (
              !this.uploadedImages.includes(this.normalizeImg(img)) &&
              !img.includes("guesteco.com") &&
              img
            ) {
              this.uploadedImages.push(this.normalizeImg(img));
            }
          }
          this.listingCount = this.uploadedData.length;
          this.countOfImages = this.uploadedImages.length;
        },
      });
    },
    async completeUploadCSV() {
      for (let i = 0; i < this.uploadedData.length; i++) {
        if (!this.uploadedData[i].main_file.includes("guesteco.com")) {
          this.uploadedData[i].main_file = "";
        }
        const { data } = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}product-category`,
          this.uploadedData[i]
        );
        this.categories.push(data);
      }
      this.successMessage = "New categories successfully uploaded!";
      this.$refs.PopupSuccess.open();
      this.uploadedData = [];
      this.uploadImage = false;
    },
    handleSuccessUploadCSV(file) {
      let trigger = true;
      for (let i = 0; i < this.uploadedData.length; i++) {
        if (
          this.normalizeImg(this.uploadedData[i].main_file) ===
          this.normalizeImg(file.name)
        ) {
          this.uploadedData[i].main_file = file.xhr.response;
          if (this.uploadedImages.includes(this.normalizeImg(file.name))) {
            this.uploadedImages.splice(
              this.uploadedImages.indexOf(this.normalizeImg(file.name)),
              1
            );
            trigger = false;
            break;
          }
        }
      }
      if (trigger) {
        this.$refs.uploadCSV.removeFile(file);
      }
    },
    customEventUploadCSV(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append(
        "lending_settings_id",
        this.$store.state.landing.settings_id
      );
      formData.append("image", _file);
    },
    openUploadImageDialog() {
      this.uploadImage = true;
      this.uploadCSV = false;
    },
    closeUploadImageDialog() {
      this.uploadImage = false;
    },
    handleFileImport() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.$refs.input.click();
    },
    async downloadImages() {
      let images = [];
      for (let i = 0; i < this.content.content.listing.length; i++) {
        for (
          let index = 0;
          index < this.content.content.listing[i].content.length;
          index++
        ) {
          let item = this.content.content.listing[i].content[index];
          if (item.main_img) {
            let id = this.getImageId(item.main_img);
            if (!images.includes(id)) {
              images.push(id);
            }
          }
          if (item.img.length) {
            for (
              let imageIndex = 0;
              imageIndex < item.img.length;
              imageIndex++
            ) {
              let id = this.getImageId(item.img[imageIndex]);
              if (!images.includes(id)) {
                images.push(id);
              }
            }
          }
        }
      }
      if (images.length) {
        const response = await ImagesService.getZipByImagesID(images);
        if (response.status === 201) {
          const link = document.createElement("a");
          link.href = response.data;
          const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
          });
          link.dispatchEvent(evt);
          link.remove();
        }
      } else {
        this.$refs.PopupError.open();
      }
    },
    closeUploadCSV() {
      this.uploadCSV = false;
    },
    openUploadCSV() {
      this.uploadedData = [];
      this.uploadCSV = true;
    },
    async parseCategories() {
      const { data } = await this.$axios.get(
        `https://omenu.com/api/restaurant/categories?id=611`
      );
      for (let i = 0; i < data.length; i++) {
        if (!data[i].parent_id) {
            let id;
            if(this.item.type === 8){
                id = +(this.selectedMenuID+""+this.item.id+'000');
            }else{
                id = this.item.id;
            }
          const category = {
            name: { en: data[i].name },
            files: [],
            hotel_id: +this.$route.params.id,
            main_file:
              data[i].image && data[i].image.includes(".svg")
                ? "https://api.guesteco.com/storage/default-landing-icons/" +
                  data[i].image.split("/")[2]
                : "",
            pdf_file: "",
            active: 1,
            menu_id: id,
            hidden_id: data[i].id,
          };
          await this.$axios.post(
            `${process.env.VUE_APP_API_URL}product-category`,
            category
          );
        }

        if (data[i].parent_id) {
          const subcategory = {
            name: { en: data[i].name },
            files: [],
            hotel_id: +this.$route.params.id,
            main_file:
              data[i].image && data[i].image.includes(".svg")
                ? "https://api.guesteco.com/storage/default-landing-icons/" +
                  data[i].image.split("/")[2]
                : "",
            pdf_file: "",
            active: 1,
            product_category_id: this.categories.find(
              (x) => x.hidden_id === data[i].parent_id
            ).id,
            hidden_id: data[i].id,
          };
          await this.$axios.post(
            `${process.env.VUE_APP_API_URL}product-sub-category`,
            subcategory
          );
        }
      }
    },
    async parseProducts() {
      const { data } = await this.$axios.get(
        `https://omenu.com/api/restaurant/products?id=611`
      );
      for (let i = 0; i < data.length; i++) {
        let categoryID, subcagoryID;
        for (let cat = 0; cat < this.categories.length; cat++) {
          if (this.categories[cat].hidden_id === data[i].category_id) {
            categoryID = this.categories[cat].id;
            break;
          }
          for (
            let subcat = 0;
            subcat < this.categories[cat].sub_categories.length;
            subcat++
          ) {
            if (
              this.categories[cat].sub_categories[subcat].hidden_id ===
              data[i].category_id
            ) {
              categoryID = this.categories[cat].id;
              subcagoryID = this.categories[cat].sub_categories[subcat].id;
              break;
            }
          }
        }
        const product = {
          name: { en: data[i].name },
          description: { en: data[i].details },
          small_description: { en: data[i].details },
          options: [],
          recommended: [],
          similar: [],
          allergens: data[i].allergens,
          video: "",
          product_category_id: categoryID ? categoryID : null,
          product_sub_category_id: subcagoryID ? subcagoryID : null,
          main_file: data[i].image
            ? "https://omenu.com/uploads/" + data[i].image
            : "",
          weight: data[i].volume_units_count,
          unit: data[i].volume_units,
          active: 1,
          files: [],
          detail_files: [],
          tags: [],
          price: data[i].price,
          currency: "Kn",
          start_time: 0,
          end_time: 0,
        };
        await this.$axios.post(`/product`, product);
      }
    },
    async deleteCategory() {
      try {
        const response = await this.$axios.delete(
          `product-category/${this.deleteItem.id}`
        );
        if (response.status === 200) {
          this.categories.splice(this.categories.indexOf(this.deleteItem), 1);
          this.successMessage = response.data;
          this.$refs.PopupSuccess.open();
        } else {
          this.successMessage = "Something going wrong, try again please!";
          this.$refs.PopupSuccess.open();
        }
      } catch (e) {
        this.error = e.message;
        this.$refs.PopupError.open();
      }
    },
    formatDate(date) {
      return moment(date).format("DD.MM.yyyy HH:mm");
    },
    async saveEditCategory() {
      try {
        const response = await this.$axios.put(
          `/product-category/${this.editCategory.id}`,
          this.editCategory
        );
        const candidate = this.categories.find(
          (x) => x.id === this.editCategory.id
        );
        if (candidate) {
          this.categories.splice(
            this.categories.indexOf(candidate),
            1,
            response.data
          );
        }
        this.successMessage = "Your category edited successful!";
        this.dialogEditCategory = false;
        this.$refs.PopupSuccess.open();
        this.editCategoryFilePdf = [];
      } catch (e) {
        this.error = e.message;
        this.$refs.PopupError.open();
      }
    },
    handleRemoveEdit(file) {
      let item = this.editCategory.files[file.removeItemIndex];
      this.editCategory.files.splice(
        this.editCategory.files.indexOf(file.removeItemIndex),
        1
      );
      if (this.editCategory.main_file === item) {
        if (this.editCategory.files.length) {
          this.editCategory.main_file = this.editCategory.files[0];
        } else {
          this.editCategory.main_file = "";
        }
      }
    },
    handleSuccessEditCategory(file) {
      if (this.editCategory.files.length === 0) {
        this.editCategory.main_file = file.xhr.response;
      }
      this.editCategory.files.push(file.xhr.response);
    },
    editDropzoneMount() {
      let mockFile = {
        accepted: true,
        cropped: true,
        kind: "image",
        ...this.editCategory,
      };
      let elements =
        this.$refs.editDropzone.$el.getElementsByClassName("dz-preview");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      for (let i = 0; i < this.editCategory.files.length; i++) {
        mockFile.removeItemIndex = i;
        this.$refs.editDropzone.manuallyAddFile(
          mockFile,
          this.editCategory.files[i]
        );
        this.$refs.editDropzone.dropzone.emit(
          "thumbnail",
          mockFile,
          this.editCategory.files[i]
        );
        this.$refs.editDropzone.dropzone.emit("complete", mockFile);
      }
    },
    closeEditCategory() {
      this.dialogEditCategory = false;
      this.categoryEditFile = false;
      this.editCategoryFilePdf = [];
    },
    openDeletePopup(item) {
      this.deleteItem = item;
      this.$refs.PopupDeleteCategory.open();
    },
    async openEditCategory(item) {
      this.editCategory = JSON.parse(JSON.stringify(item));
      if (this.editCategory.pdf_file) {
        this.categoryEditFile = true;
        if (typeof this.editCategory.pdf_file === "string") {
          this.editCategoryFilePdf = await this.createFile(
            this.editCategory.pdf_file
          );
        }
      } else {
        this.categoryEditFile = false;
      }
      let mockFile = {
        accepted: true,
        cropped: true,
        kind: "image",
        ...this.editCategory,
      };
      if (this.$refs.editDropzone) {
        let elements =
          this.$refs.editDropzone.$el.getElementsByClassName("dz-preview");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
        for (let i = 0; i < this.editCategory.files.length; i++) {
          mockFile.removeItemIndex = i;
          this.$refs.editDropzone.manuallyAddFile(
            mockFile,
            this.editCategory.files[i]
          );
          this.$refs.editDropzone.dropzone.emit(
            "thumbnail",
            mockFile,
            this.editCategory.files[i]
          );
          this.$refs.editDropzone.dropzone.emit("complete", mockFile);
        }
      }
      this.dialogEditCategory = true;
    },
    async changeCategoryStatus(item) {
      try {
        if (item.active) {
          item.active = 0;
        } else {
          item.active = 1;
        }
        await this.$axios.put(`/product-category/${item.id}`, item);
      } catch (e) {
        this.error = e.message;
        this.$refs.PopupError.open();
      }
    },
    async onFileChange(file, item) {
      if (file) {
        try {
          let formData = new FormData();
          formData.append("lending_settings_id", this.landing.settings_id);
          formData.append("image", file);
          formData.append("file", file);
          const response = await this.$axios.post("upload-image", formData);
          item.pdf_file = response.data;
        } catch (e) {
          this.error = e.message;
          this.$refs.PopupError.open();
        }
      }
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    closeDialogCreate() {
      this.dialogCreateCategory = false;
      this.categoryCreateFile = false;
    },
    async createNewCategory() {
      this.categoryCreateFile = false;
      this.createdCategory.hotel_id = this.$route.params.id;
        let id;
        if(this.item.type === 8){
            id = +(this.selectedMenuID+""+this.item.id+'000');
        }else{
            id = this.item.id;
        }
      this.createdCategory.menu_id = id;

      const response = await this.$axios.post(
        `${process.env.VUE_APP_API_URL}product-category`,
        this.createdCategory
      );
      if (!this.categories.length) {
        this.selectedCategory = response.data;
      }
      this.categories.push(response.data);
      this.dialogCreateCategory = false;
      this.successMessage = "New category successfull created!";
      this.$refs.PopupSuccess.open();
    },
    openStyles() {
      this.styles = !this.styles;
    },
    changeMainImage(obj, img) {
      obj.main_file = img;
    },
    async handleSuccessCreateCategory(file) {
      if (!this.createdCategory.main_file) {
        this.createdCategory.main_file = file.xhr.response;
      }
      this.createdCategory.files.push(file.xhr.response);
    },
    async customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append("lending_settings_id", this.landing.settings_id);
      formData.append("image", _file);
    },
    async createProductCategory() {
      this.createdCategory = JSON.parse(JSON.stringify(this.defaultCategory));
      this.dialogCreateCategory = true;
    },
    async handleRemove(file) {
      if (file.name.includes("guesteco.com")) {
        if (this.createdCategory.main_file === file.name) {
          this.createdCategory.main_file = "";
        }

        await ImagesService.deleteImage(file.name);
      } else {
        if (this.createdCategory.main_file === file.xhr.response) {
          this.createdCategory.main_file = "";
        }

        await ImagesService.deleteImage(file.xhr.response);
      }
    },
  },
};
</script>

<style scoped></style>
