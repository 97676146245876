import { render, staticRenderFns } from "./ProductUploadCsvDialog.vue?vue&type=template&id=3b71dc64&scoped=true&"
import script from "./ProductUploadCsvDialog.vue?vue&type=script&lang=js&"
export * from "./ProductUploadCsvDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b71dc64",
  null
  
)

export default component.exports