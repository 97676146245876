<template>
    <v-dialog :fullscreen="$store.state.isMobile" max-width="410px"
        v-model="dialog"
    >
        <div class="popup__content popup__content_success">
            <v-btn class="popup__close" small icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h5 class="popup__title" style="margin-bottom:10px;">Error</h5>
            <h4 class="popup__text" style="margin-bottom:10px;text-align: center;">{{title}}</h4>
            <v-btn class="bnt btn_primary"
                color="primary"
                depressed
                block
                @click="close"
            >
                {{$t('app["Закрыть"]')}}
            </v-btn>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        title: String
    },
    data: () => ({
        dialog: false,
    }),
    methods: {
        open() {
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.popup__content_success {
    // padding-top: 140px !important;
    // background: #fff url(~@/assets/icons/success.svg) center 50px/64px no-repeat;
}
</style>
